import React from "react";
import styles from "./Pagination.module.scss";

export default function Pagination({ currentPage, totalPages }: { currentPage: number; totalPages: number; }) {
  return (
    <div className={styles.pagination}>
      <button 
        className={styles.pagination__prev}
        disabled={currentPage === 1}
        onClick={() => {}}
      >
        <svg
          width="10"
          height="17"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.91382 1.50101L1.88631 8.48659L8.91382 15.4722"
            stroke="#0D1814"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {Array.from({ length: totalPages }, (_, i) => (
        <button
          key={i}
          className={`${styles.pagination__page} ${currentPage === i + 1 ? styles.active : ""}`}
          onClick={() => {}}
        >
          {i + 1}
        </button>
      ))}
      <button 
        className={styles.pagination__next}
        disabled={currentPage === totalPages}
        onClick={() => {}}
      >
        <svg
          width="10"
          height="17"
          viewBox="0 0 10 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.78666 15.4838L8.79104 8.47501L1.74048 1.5127"
            stroke="#0D1814"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
}
