import { ReactNode } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Heading from "./Heading";
import Map from "../map/Map";

import arrLeft from "../../assets/icons/arrLeft.svg";
import arrRight from "../../assets/icons/arrRight.svg";
import trashIcon from "../../assets/icons/trash.svg";

import styles from "./NestedLayout.module.scss";

interface NavLinkType {
  icon?: string;
  label: string;
  link: string;
}

function Aside({ navLinks }: { navLinks: NavLinkType[] }) {
  return (
    <>
      <aside className={styles["aside"]}>
        <div className={styles["aside__heading"]}>
          <div className={styles["aside__heading__media"]}>
            <Map />
          </div>
          {/* <div className={styles["aside__heading__title"]}>sfd</div>
          <div className={styles["aside__heading__subtitle"]}>sfd</div> */}
        </div>
        <div className={styles["aside__nav"]}>
          <div className={styles["aside__nav__heading"]}>
            <h3 className={styles["aside__nav__heading__title"]}>Field</h3>
            <h4
              className={
                styles["aside__nav__heading__title"] +
                " " +
                styles["aside__nav__heading__title--main"]
              }
            >
              Grapes Field
            </h4>
            <button className={styles["aside__nav__heading__action"]}>
              <img src={trashIcon} alt="" />
            </button>
          </div>
          {navLinks.map((navLink, index) => (
            <NavLink
              key={navLink.label.replace(" ", "_") + index}
              className={({ isActive, isPending }) =>
                (isActive
                  ? styles["aside__nav__link--is-active"]
                  : isPending
                  ? styles["aside__nav__link--is-pending"]
                  : "") +
                " " +
                styles["aside__nav__link"]
              }
              to={navLink.link}
            >
              {navLink.icon && <img src={navLink.icon} alt={navLink.label} />}
              {navLink.label}
            </NavLink>
          ))}
        </div>
      </aside>
    </>
  );
}

function ContentHeading({ navLinks }: { navLinks: NavLinkType[] }) {
  const navigate = useNavigate();
  const location = useLocation();

  const currentIndex = navLinks.findIndex((navLink) => {
    return location.pathname.replace("/", "") === navLink.link;
  });

  const goTo = (n: number) => {
    if (currentIndex + n > navLinks.length - 1 || currentIndex + n < 0)
      return false;
    navigate(navLinks[currentIndex + n]["link"]);
  };

  return (
    <div className={styles["content__heading"]}>
      <button
        className={styles.nav__logo}
        type="button"
        onClick={() => {
          goTo(-1);
        }}
        disabled={currentIndex === 0}
      >
        <img src={arrLeft} alt="Arr Left" />
      </button>
      <h2 className={styles["content__heading__title"]}>
        {navLinks[currentIndex]["label"]}
      </h2>
      <button
        className={styles.nav__logo}
        type="button"
        onClick={() => {
          goTo(1);
        }}
        disabled={currentIndex === navLinks.length - 1}
      >
        <img src={arrRight} alt="Arr Right" />
      </button>
    </div>
  );
}

export default function NestedLayout({
  pageTitle,
  navLinks,
  actionTitle,
  action,
  children,
  optionalActionTitle,
  optionalAction,
  pageIcon
}: {
  pageTitle: string;
  navLinks: NavLinkType[];
  actionTitle?: string;
  action?: () => void;
  children: ReactNode;
  optionalActionTitle?: string;
  optionalAction?: () => void;
  pageIcon?: string;
}) {
  return (
    <>
      <main className={styles.main}>
        <Heading
          pageTitle={pageTitle}
          actionTitle={actionTitle}
          pageIcon={pageIcon}
          action={action}
        />
        <Aside navLinks={navLinks} />
        <section className={styles.content}>
          <ContentHeading navLinks={navLinks} />
          {children}
        </section>
      </main>
    </>
  );
}
