import pencilIcon from "../../assets/icons/pencil.svg";
import copyIcon from "../../assets/icons/copy.svg";

export default function WeeklyEvent({
  title,
  start,
  end,
  priority,
  assignee,
}: {
  title: string;
  start: Date;
  end: Date;
  priority?: string;
  assignee?: string;
}) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const days = ["Sun", "Mon", "Tues", "Wed", "Thrus", "Fri", "Sat"];

  return (
    <>
      <div className="rbc-weekly-event rbc-event-wrapper">
        <div className="rbc-weekly-event-content">
          <div className="rbc-weekly-event-content-inner">
            <div className="rbc-weekly-event-content-duration">
              {start.getHours()}:{start.getMinutes()}{" "}
              <span className="rbc-weekly-event-content-duration-devider">
                |
              </span>
              {Math.abs(start.getTime() - end.getTime()) / (1000 * 60 * 60)}hr
            </div>
            <div className="rbc-weekly-event-content-title">{title}</div>
            <div className="rbc-weekly-event-content-date">
              {days[start.getDay()]} {start.getDay()} {months[start.getMonth()]}{" "}
              - {days[end.getDay()]} {end.getDay()} {months[end.getMonth()]}
            </div>
            <div className="rbc-weekly-event-content-actions">
              <button className="rbc-weekly-event-content-actions-button">
                <img src={pencilIcon} alt="Edit task" />
              </button>
              <button className="rbc-weekly-event-content-actions-button">
                <img src={copyIcon} alt="Copy task" />
              </button>
            </div>
            <div className="rbc-weekly-event-content-info">
              <span className="rbc-weekly-event-content-info-priority">
                {priority}
              </span>
              {assignee && (
                <span className="rbc-weekly-event-content-info-assignee">
                  {assignee.split(" ")[0].charAt(0)}{" "}
                  {assignee.split(" ")[1].charAt(0)}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
