import { useContext,useEffect } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { useRef, useState } from "react";
import {
  Form,
  FormInput,
  FormGroup,
  FormButton,
  FormTextarea,
  FormSelect,
} from "./Form";
import dangerIcon from "../../assets/icons/redDanger.svg";

import styles from "./NewFormTask.module.scss";
import { Trans } from "@lingui/react";

interface NewTaskFormProps {
  onClose: () => void;
  onSuccess: () => void;
}

export default function NewTaskForm({ onClose, onSuccess }: NewTaskFormProps) {
  const [formValues, setFormValues] = useState({
    taskName: "",
    workOrder: "",
    status: "",
    priority: "",
    assignee: "",
    taskType: "",
    operations: "",
    taskInputMaterials: "",
    taskResources: "",
    field: "",
    cropZone: "",
    description: "",
    startDate: "",
    endDate: "",
  });
  const [formError, setFormError] = useState("");
  const [invalidField, setInvalidField] = useState<string[]>([]);
  const fieldref = useRef<{
    [key: string]: HTMLInputElement | HTMLSelectElement | null;
  }>({});

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setInvalidField((prev) => prev.filter((field) => field !== name));
  };

  const validateDates = (start: string, end: string) => {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);

    const startDate = new Date(start);
    startDate.setHours(0, 0, 0, 0);
    const endDate = new Date(end);
    endDate.setHours(0, 0, 0, 0);

    if (startDate < todayDate) {
      return "The start date should be today or a future date.";
    }

    if (endDate < todayDate || endDate < startDate) {
      return "The end date must be today or a future date and cannot be earlier than the start date.";
    }

    return null;
  };

  const validateForm = () => {
    let isValid = true;
    let errorMessage = "";
    const invalidFieldsArray: string[] = [];

    Object.entries(formValues).forEach(([key, value]) => {
      if (!value || value === "please select") {
        isValid = false;
        errorMessage = "Please populate the mandatory fields";
        invalidFieldsArray.push(key);
      }
    });

    const dateError = validateDates(formValues.startDate, formValues.endDate);
    if (dateError) {
      errorMessage = dateError;
      isValid = false;
      invalidFieldsArray.push("startDate", "endDate");
    }

    setFormError(errorMessage);
    setInvalidField(invalidFieldsArray);

    if (!isValid && invalidFieldsArray.length > 0) {
      const firstInvalidField = invalidFieldsArray[0];

      fieldref.current[firstInvalidField]?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }

    return isValid;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setFormError("");
    onClose();
    onSuccess();
  };

  return (
    <>
      <Form customClassName={styles["custom-form"]}>
        {formError && (
          <div className={styles["custom-form__error-container"]}>
            <p
              className={styles["custom-form__error-container__error-message"]}
            >
              <img src={dangerIcon} alt="" />
              {formError}
            </p>
          </div>
        )}
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormInput
            id="task-name"
            name="taskName"
            label="Task Name"
            isRequired={true}
            value={formValues.taskName}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.taskName = el)}
            customClassName={
              invalidField.includes("taskName") ? styles["input-error"] : ""
            }
          />
          <FormSelect
            id="work-order"
            name="workOrder"
            label="Work Order"
            selectOptions={[
              "please select",
              "Option 1",
              "Option 2",
              "Option 3",
            ]}
            customClassName={
              invalidField.includes("workOrder") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.workOrder}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.workOrder = el)}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormSelect
            id="status"
            name="status"
            label="Status"
            selectOptions={[
              "please select",
              "Option 1",
              "Option 2",
              "Option 3",
            ]}
            customClassName={
              invalidField.includes("status") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.status}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.status = el)}
          />
          <FormSelect
            id="priority"
            name="priority"
            label="Priority"
            selectOptions={[
              "please select",
              "Option 1",
              "Option 2",
              "Option 3",
            ]}
            customClassName={
              invalidField.includes("priority") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.priority}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.priority = el)}
          />
          <FormSelect
            id="assignee"
            name="assignee"
            label="Assignee"
            selectOptions={[
              "please select",
              "Option 1",
              "Option 2",
              "Option 3",
            ]}
            customClassName={
              invalidField.includes("assignee") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.assignee}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.assignee = el)}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormSelect
            id="priority"
            name="priority"
            label="Priority"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
          />
          <FormSelect
            id="task-type"
            name="taskType"
            label="Task Type"
            selectOptions={[
              "please select",
              "Option 1",
              "Option 2",
              "Option 3",
            ]}
            customClassName={
              invalidField.includes("taskType") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.taskType}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.taskType = el)}
          />
          <FormSelect
            id="operations"
            name="operations"
            label="Operations"
            selectOptions={[
              "please select",
              "Option 1",
              "Option 2",
              "Option 3",
            ]}
            customClassName={
              invalidField.includes("operations") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.operations}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.operations = el)}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            gridRow: "span 2",
            margin: 0,
          }}
        >
          <FormSelect
            id="task-input-materials"
            name="taskInputMaterials"
            label="Task Input Materials"
            selectOptions={[
              "please select",
              "Option 1",
              "Option 2",
              "Option 3",
            ]}
            customClassName={
              invalidField.includes("taskInputMaterials")
                ? styles["input-error"]
                : ""
            }
            isRequired={true}
            value={formValues.taskInputMaterials}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.taskInputMaterials = el)}
          />
          <FormSelect
            id="farm"
            name="farm"
            label="Farm"
            selectOptions={["Option 1", "Option 2", "Option 3"]}
            isRequired={true}
            value={formValues.taskResources}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.taskResources = el)}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            margin: 0,
          }}
        >
          <FormSelect
            id="field"
            name="field"
            label="Field"
            selectOptions={[
              "please select",
              "Option 1",
              "Option 2",
              "Option 3",
            ]}
            customClassName={
              invalidField.includes("field") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.field}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.field = el)}
          />
          <FormSelect
            id="crop-zone"
            name="cropZone"
            label="Crop Zone"
            selectOptions={[
              "please select",
              "Option 1",
              "Option 2",
              "Option 3",
            ]}
            customClassName={
              invalidField.includes("cropZone") ? styles["input-error"] : ""
            }
            isRequired={true}
            value={formValues.cropZone}
            onChange={handleInputChange}
            inputRef={(el) => (fieldref.current.cropZone = el)}
          />
        </FormGroup>
        <FormGroup
          isRow={true}
          customCSS={{
            gridColumn: "span 2",
            flexDirection: "row",
            margin: 0,
          }}
          customClassName={styles["custom-form__textarea"]}
        >
          <FormTextarea
            id="description"
            name="description"
            label="Description"
            isRequired={true}
            customClassName={
              invalidField.includes("description") ? styles["input-error"] : ""
            }
            value={formValues.description}
            onChange={handleInputChange}
          />
          <FormInput
            id="start-date"
            name="startDate"
            type="date"
            label="Start Date"
            isRequired={true}
            customCSS={{
              flex: 1,
            }}
            customClassName={
              invalidField.includes("startDate") ? styles["input-error"] : ""
            }
            value={formValues.startDate}
            onChange={handleInputChange}
          />
          <FormInput
            id="end-date"
            name="endDate"
            type="date"
            label="End Date"
            isRequired={true}
            customCSS={{
              flex: 1,
            }}
            customClassName={
              invalidField.includes("endDate") ? styles["input-error"] : ""
            }
            value={formValues.endDate}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup
          customClassName={styles["custom-form__button"]}
          customCSS={{
            margin: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FormButton action={handleSubmit}>
            <Trans id="form.create_task_button">Create Task</Trans>
          </FormButton>
        </FormGroup>
      </Form>
    </>
  );
}
