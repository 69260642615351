import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import App from "./App";
import Login from "./routes/login";
import Tasks from "./routes/tasks";
import Callback from "./routes/callback";
import FarmSetup from "./routes/farm-setup";
import ProfileNotifications from "./components/profile/ProfileNotifications";
import ViewProfile from "./components/profile/ViewProfile";
import LayoutProfile from "./components/profile/LayoutProfile";
import ProfileLanguages from "./components/profile/ProfileLanguages";
import FieldDetails from "./routes/fieldDetails";
import FieldDetailsInformation from "./routes/fieldDetailsInformation";
import FieldDetailsTasks from "./routes/fieldDetailsTasks";
import ErrorPage from "./error-page";
import Root from "./routes/root";
import TaskRecommendations from "./routes/taskRecommendations";
import "./index.scss";
import { ThemeContextProvider } from "./useContext/context";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { LocaleProvider } from "./useContext/localeContext";
import { I18nProvider } from '@lingui/react';
import { i18n } from '@lingui/core';
import { UserProvider } from "./useContext/UserContext";

const AppWrapper = () => {
  const [isNotificationsOn, setIsNotificationsOn] = useState<boolean>(true);
  const [selectedLanguage, setSelectedLanguage] =
    useState<string>("English (US)");

  const handleNotificationToggle = (value: boolean) => {
    setIsNotificationsOn(value);
  };

  const handleLanguageChange = (newLanguage: string) => {
    setSelectedLanguage(newLanguage);
  };

  return (
    <>
      <ProfileNotifications
        isNotificationsOn={isNotificationsOn}
        onNotificationToggle={handleNotificationToggle}
        onClosed={() => {}}
      />
      <ProfileLanguages
        selectedLanguage={selectedLanguage}
        onLanguageChange={handleLanguageChange}
        onClosed={() => {}}
      />
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/tasks",
        element: <Tasks />,
      },
      {
        path: "/profile/*",
        element: (
          <>
            <UserProvider>
              <LayoutProfile />
            </UserProvider>
          </>
        ),
      },
      {
        path: "/view-profile",
        element: <ViewProfile onClosed={() => {}} />,
      },
      {
        path: "/profile-notifications",
        element: <AppWrapper />,
      },
      {
        path: "/profile-languages",
        element: <AppWrapper />,
      },
      {
        path: "task-recommendations",
        element: <TaskRecommendations />,
      },
      {
        path: "task-recommendations",
        element: <TaskRecommendations />,
      },
      {
        path: "/field-details",
        element: <FieldDetails />,
        children: [
          {
            path: "field-information",
            element: <FieldDetailsInformation />,
          },
          {
            path: "field-tasks",
            element: <FieldDetailsTasks />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/farm-setup",
    element: <FarmSetup />,
  },
  {
    path: "/callback",
    element: <Callback />,
  },
]);

async function setupI18n(defaultSection:string = 'profile', defaultLocale:string = 'en-US') {
  try {
    const { messages } = await import(`./locales/${defaultSection}/${defaultLocale}/messages`);
    i18n.load(defaultLocale, messages);
    await i18n.activate(defaultLocale);
    renderApp();
  } catch (error) {
    console.error("Failed to initialize i18n for section:", defaultSection, error);
  }
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function renderApp() {
  root.render(
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
      <LocaleProvider>
        <ThemeContextProvider>
          <RouterProvider router={router} />
        </ThemeContextProvider>
      </LocaleProvider>
      </I18nProvider>
    </React.StrictMode>
  );
}

setupI18n();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
