import { useState } from "react";
import { NavigateAction } from "react-big-calendar";

import calendarIcon from "../../assets/icons/calendar.svg";

export default function Toolbar({
  label,
  date,
  onNavigate,
  showTodayButton = false,
  showDatePicker = true,
  showSearch = false,
}: {
  label: string;
  date: Date;
  onNavigate: (action: NavigateAction, newDate?: Date) => void;
  showTodayButton?: boolean;
  showDatePicker?: boolean;
  showSearch?: boolean;
}) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [currentDate, setCurrentDate] = useState(date);

  return (
    <>
      <div className="rbc-toolbar">
        <div className="rbc-toolbar-date">
          {showTodayButton && (
            <div className="rbc-toolbar-date-today">
              <button
                className="rbc-toolbar-date-today-button"
                onClick={() => {
                  onNavigate("TODAY");
                }}
              >
                Today
              </button>
            </div>
          )}
          {showDatePicker && (
            <div className="rbc-toolbar-date-picker">
              <img
                className="rbc-toolbar-date-picker-icon"
                src={calendarIcon}
                alt="Pick a date"
              />
              <input
                className="rbc-toolbar-date-picker-input"
                type="date"
                title="Pick a date"
                onChange={(event) => {
                  const newDate = new Date(event.target.value);
                  onNavigate("DATE", newDate);
                  setCurrentDate(newDate);
                }}
              />
              <p className="rbc-toolbar-date-picker-placeholder">
                {months[currentDate.getMonth()]}
              </p>
            </div>
          )}
        </div>
        <button
          className="rbc-toolbar-button rbc-toolbar-button--prev"
          onClick={() => onNavigate("PREV")}
          type="button"
        >
          Back
        </button>
        <span className="rbc-toolbar-label">{label}</span>
        <button
          className="rbc-toolbar-button rbc-toolbar-button--next"
          onClick={() => onNavigate("NEXT")}
          type="button"
        >
          Next
        </button>
        <div
          style={{
            marginLeft: "auto",
          }}
        >
          {showSearch && <input type="text" />}
        </div>
      </div>
    </>
  );
}
