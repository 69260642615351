import Notification from "../notification/Notification";
import styles from "./Heading.module.scss";
import recommendationIcon from "../../assets/icons/tasks/recommendation.svg"; // Ensure this icon is available in your assets

export default function Heading({ pageTitle }: { pageTitle: React.ReactNode }) {
  return (
    <>
      <div className={styles.heading}>
        <div className={styles["heading__icon-wrapper"]}>
          <img src={recommendationIcon} alt="Page icon" />
        </div>

        <h1 className={styles.heading__title}>{pageTitle}</h1>
        <div className={styles.heading__actions}>
          <Notification />
        </div>
      </div>
    </>
  );
}
