import React, { useState } from 'react';
import styles from './DeleteTaskAssigned.module.scss';
import { FormButton } from '../form/Form';
import alertIcon from "../../assets/icons/tasks/alertTask.svg";

interface DeleteTaskProps {
  onConfirm: () => void;
}

const DeleteTask: React.FC<DeleteTaskProps> = ({onConfirm}:DeleteTaskProps) => {
  const [reason, setReason] = useState('');
  const [justification, setJustification] = useState('');
  const [error,setError] = useState(false);

  const reasons = [
    "expired",
    "incomplete information",
    "lack of budget",
    "lack of resources",
    "not relevant",
    "other"
  ];

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if(!reason){
      alert("Please select a reason");
      return;
    }
    if(justification.trim()===""){
      setError(true);
      return;
    }
    // console.log({ reason, justification });
    onConfirm();
  };

  return (
    <div className={styles.container}>
      {
        error && (
          <div className={styles.error}>
            <span className={styles.errorIcon}>
              <img src={alertIcon} alt="ALertIcon" />
            </span>
            <span className={styles.errorMessage}>Please provide cancellation reason</span>
          </div>
        )
      }
      <form className={styles.form} onSubmit={handleSubmit}>
        <label className={styles.reasonLabel} htmlFor="reason">
          REASON OF REJECTION*
          <select id="reason" value={reason} onChange={e => setReason(e.target.value)} required>
            <option value="">Select a reason</option>
            {reasons.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </label>
        <label htmlFor="justification">
          Please justify the reason of rejection <span className={styles.charLimit}>(1000 char max)*</span>
          <textarea
            id="justification"
            value={justification}
            onChange={e => setJustification(e.target.value)}
            maxLength={1000}
          />
        </label>
        <FormButton type="submit">Submit</FormButton>
      </form>
    </div>
  );
}

export default DeleteTask;
