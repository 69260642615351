import { useEffect, useState } from "react";
import PageHeading from "../components/page/Heading";
import SectionWrapper from "../components/section/SectionWrapper";
import SectionHeading from "../components/section/SectionHeading";
import {
  Form,
  FormInput,
  FormGroup,
  FormButton,
} from "../components/form/Form";
import CustomCalendar from "../components/calendar/CustomCalendar";

import pageIcon from "../assets/icons/files.svg";
import pencilIcon from "../assets/icons/pencil.svg";
import stopIcon from "../assets/icons/stop.svg";
import alertIcon from "../assets/icons/alert.svg";

import styles from "../styles/routes/tasks.module.scss";
import Modal from "../components/modal/Modal";
import NewTaskForm from "../components/form/NewTaskForm";
import {TaskList} from "../components/list/TaskList";
import successIcon from "../assets/icons/successful.svg"

export default function Tasks() {
  const [isMobile, setIsMobile] = useState(false);
  const [isNewTaskModalOpen, setIsNewTaskModalOpen] = useState(false);
  const [isTasksModalOpen, setIsTasksModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState<boolean>(false);

  const observer = new ResizeObserver(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  });

  useEffect(() => {
    observer.observe(document.body);
  }, []);

  return (
    <>
      <main className={styles.main}>
        <PageHeading
          pageTitle="Tasks"
          pageIcon={pageIcon}
          action={() => {
            setIsNewTaskModalOpen(true);
          }}
          actionTitle="New Task"
        />
        <SectionWrapper
          customStyle={{
            gridColumn: "span 12",
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            alignItems: "stretch",
            padding: 0,
            height: "100%",
            border: 0,
          }}
        >
          <CustomCalendar />
        </SectionWrapper>
        <div
          style={{
            gridColumn: "1 / -1",
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(35rem, 1fr)",
            gap: "var(--gap)",
          }}
        >
          <SectionWrapper>
            <SectionHeading
              title="Drafts"
              label="2"
              icon={pencilIcon}
              action={() => {
                setModalTitle("Drafts");
                setIsTasksModalOpen(true);
              }}
            />
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeading
              title="Canceled Tasks"
              label="0"
              icon={stopIcon}
              iconBackground="color-mix(
                in srgb,
                #ecb365 20%,
                var(--clr-white) 80%
              )"
              action={() => {
                setModalTitle("Canceled Tasks");
                setIsTasksModalOpen(true);
              }}
            />
          </SectionWrapper>
          <SectionWrapper>
            <SectionHeading
              title="Overdue Tasks"
              label="5"
              icon={alertIcon}
              iconBackground="color-mix(
                in srgb,
                #ecb365 20%,
                var(--clr-white) 80%
              )"
              action={() => {
                setModalTitle("Overdue Tasks");
                setIsTasksModalOpen(true);
              }}
            />
          </SectionWrapper>
        </div>
      </main>

      <Modal
        headingTitle="Create New Task"
        headingIcon={pageIcon}
        isOpen={isNewTaskModalOpen}
        onClose={() => setIsNewTaskModalOpen(false)}
      >
        <NewTaskForm
          onSuccess={() => {
            setIsNewTaskModalOpen(false);
            setIsSuccessModalOpen(true);
          }}
          onClose={() => setIsNewTaskModalOpen(false)}
        />
      </Modal>
      <Modal
        headingTitle={modalTitle}
        isOpen={isTasksModalOpen}
        onClose={() => setIsTasksModalOpen(false)}
      >
        <TaskList showDate={true} />
      </Modal>

      <Modal
        headingTitle=""
        onClose={() => setIsSuccessModalOpen(false)}
        showCloseButton={false}
        isOpen={isSuccessModalOpen}
        size="md"
      >
        <div className={styles["success-modal-content"]}>
          <img src={successIcon} alt="" />
          <h2 className={styles["success-modal__title"]}>Task Added!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully created a New task. <br />
            It will now appear in your Task list.
          </p>
          <FormButton
            children="Got it!"
            action={() => setIsSuccessModalOpen(false)}
          />
        </div>
      </Modal>
    </>
  );
}
