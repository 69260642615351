import React from "react";
import Heading from "../components/task-recommendations/Heading";
import Pagination from "../components/task-recommendations/Pagination";
import RecommendationItem from "../components/task-recommendations/RecommendationItem";
import SectionWrapper from "../components/section/SectionWrapper";
import RecommendationsList from "../components/task-recommendations/RecommendationList";
import styles from "../styles/routes/taskRecommendations.module.scss";

import useIsMobile from "../hooks/useIsMobile";

const recommendationsData = [
  { title: "Fertilization recommendation", due: "01/12" },
  { title: "Irrigation recommendation", due: "24/12" },
  { title: "Harvesting recommendation", due: "15/12" },
];

const TaskRecommendations = () => {
  const isMobile = useIsMobile();
  return (
    <>
      <main className={styles.main}>
        <Heading pageTitle="Recommendations" />
        <SectionWrapper
          customStyle={{
            padding: "2rem",
            border: "1px solid var(--clr-lightgreen)",
            borderRadius: isMobile ? "1.6rem" : "1rem",
            marginTop: "2rem",
            display: "flex",
            flexDirection: "column",
            height: "fit-content",
            marginBottom: isMobile? "5rem" : "0",
            paddingBottom: "2rem",
            scrollbarWidth: "none",
          }}
        >
          {isMobile ? (
            <div className={styles.mobileHeaderWrapper}>
              <h2
                className={styles.subheading}
                style={{ color: "var(--clr-primary)" }}
              >
                Recommendations for your Farm
              </h2>
              <div className={styles.mobileFooter}>
                <span className={styles.paginationText}>1-20 of 55</span>
                <Pagination currentPage={1} totalPages={4} />
              </div>
            </div>
          ) : (
            <div className={styles.headerWrapper}>
              <div className={styles.header}>
                <h2 className={styles.subheading}>
                  Recommendations for your Farm
                </h2>
                <span className={styles.paginationText}> | 1-20 of 55</span>
              </div>
              <div className={styles.pagination}>
                <Pagination currentPage={1} totalPages={4} />
              </div>
            </div>
          )}
          <hr className={styles.divider} />
          <RecommendationsList recommendations={recommendationsData}/>
        </SectionWrapper>
      </main>
    </>
  );
};

export default TaskRecommendations;
