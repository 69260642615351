import styles from "../../styles/routes/FarmSetup.module.scss";
import type { SetupProps } from "../../types";

import { useContext, useEffect } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";

type TGetStartedProps = SetupProps;

const GetStarted: React.FC<TGetStartedProps> = ({ page, changePage }) => {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  return (
    <div className={styles["main__section-starting"]}>
      <div style={{ width: "100%" }}>
        <h2><Trans id="getStarted.heading1">Let's start</Trans></h2>
        <h2
          style={{
            fontSize: "2.7rem",
            fontWeight: "300",
          }}
        >
          <Trans id="getStarted.heading2">Setting Up Your Farm</Trans>
        </h2>
        <p><Trans id="getStarted.introduction">Hi Sam, let's start working on your farm settings.</Trans></p>
        <button
          onClick={() => changePage({ page, isForward: true })}
          className="form__button"
        >
          <Trans id="getStarted.button">Get Started</Trans>
        </button>
      </div>
    </div>
  );
};

export default GetStarted;
