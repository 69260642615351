import { useContext,useEffect } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import LocationLabel from "../location/LocationLabel";

import cloudyIcon from "../../assets/icons/weather/cloudy.svg";

import styles from "../../styles/Weather.module.scss";

import { Trans } from "@lingui/react";

export default function Weather() {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem('appLocale') || 'en-US';
    setLocale(currentLocale, 'weather');
  }, [setLocale]);

  return (
    <>
      <div className={styles["weather"]}>
      <div className={styles["weather__info"]}>
        <div className={styles["weather__temperature"]}>24°</div>
        <LocationLabel
          locationName="Giri Purno"
          customClassName={styles["weather__location"]}
        />
        <p className={styles["weather__comment"]}>
          <Trans id="weather.comment">Today is partly sunny day!</Trans>
        </p>
      </div>
      <img
        className={styles["weather__icon"]}
        src={cloudyIcon}
        alt="Cloudy Weather"
      />
      <div className={styles["weather__additional"]}>
        <div className={styles["weather__additional__item"]}>
          <p className={styles["weather__additional__title"]}>77%</p>
          <p className={styles["weather__additional__subtitle"]}>
            <Trans id="weather.humidity">Humidity</Trans>
          </p>
        </div>
        <div className={styles["weather__additional__item"]}>
          <p className={styles["weather__additional__title"]}>&lt; 0.1 mm</p>
          <p className={styles["weather__additional__subtitle"]}>
            <Trans id="weather.precipitation">Precipitation</Trans>
          </p>
        </div>
        <div className={styles["weather__additional__item"]}>
          <p className={styles["weather__additional__title"]}>6 km/h</p>
          <p className={styles["weather__additional__subtitle"]}>
            <Trans id="weather.windSpeed">Wind Speed</Trans>
          </p>
        </div>
      </div>
    </div>
    </>
  );
}
