import { useContext, useEffect } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Link } from "react-router-dom";
import styles from "../../styles/SectionHeading.module.scss";

import linkIcon from "../../assets/icons/seeAllArr.svg";

import { Trans } from "@lingui/react";
import filterIcon from "../../assets/icons/filterIcon.svg";

export default function SectionHeading({
  title,
  label,
  action,
  icon,
  iconBackground,
  filterAction,
  filterCount = 0,
}: {
  title: string;
  label?: string;
  action?: () => void;
  icon?: string;
  iconBackground?: string;
  filterAction?: () => void;
  filterCount?: number;
}) {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem('appLocale') || 'en-US';
    setLocale(currentLocale, 'section');
  }, [setLocale]);

  return (
    <>
      <div className={styles.heading}>
        <h2 className={styles.heading__title}>
          {icon && (
            <img
              className={styles.heading__icon}
              src={icon}
              alt={title}
              style={{
                background: iconBackground,
              }}
            />
          )}{" "}
          {title} {label && <span>{label}</span>}
        </h2>
        {filterAction && (
          <button className={styles.heading__filter} onClick={filterAction}>
            <span>Filter</span>
            {filterCount > 0 && (
              <span className={styles.heading__number}>{filterCount}</span>
            )}
            <img src={filterIcon} alt="Filter Icon" />
          </button>
        )}
        {action && (
          <button className={styles.heading__link} onClick={action}>
            <span><Trans id="sectionHeading.seeAll">See All</Trans></span>
            <img src={linkIcon} alt="See All" />
          </button>
        )}
      </div>
    </>
  );
}
