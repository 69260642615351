import ProfileHeading from "./ProfileHeading";
import ToggleNotifications from "./ToggleNotifications";
import React, { useState, useEffect } from "react";
import styles from "../../components/profile/ProfileNotifications.module.scss";
import notification from "../../assets/icons/profile/notification-gray.svg";
import { Trans } from "@lingui/react";
import { useUser } from "../../useContext/UserContext";
import useIsMobile from "../../hooks/useIsMobile";
import closeIcon from "../../assets/icons/close.svg"
import bell from "../../assets/icons/profile/notification-gray.svg"
interface ProfileNotificationProps {
  isNotificationsOn: boolean;
  onNotificationToggle: (value: boolean) => void;
  onClosed: () => void;
}

interface TaskNotifications {
  overdue: boolean;
  canceled: boolean;
  upcoming: boolean;
  completed: boolean;
}

export default function ProfileNotifications({
  isNotificationsOn,
  onClosed
}: ProfileNotificationProps) {
  const { user } = useUser();
  const isMobile = useIsMobile();
  const [taskNotifications, setTaskNotifications] = useState<TaskNotifications>(
    {
      overdue: isNotificationsOn,
      canceled: isNotificationsOn,
      upcoming: isNotificationsOn,
      completed: isNotificationsOn,
    }
  );

  useEffect(() => {
    if (!isNotificationsOn) {
      setTaskNotifications({
        overdue: false,
        canceled: false,
        upcoming: false,
        completed: false,
      });
    } else {
      setTaskNotifications({
        overdue: true,
        canceled: true,
        upcoming: true,
        completed: true,
      });
    }
  }, [isNotificationsOn]);

  const handleTaskToggle = (task: keyof TaskNotifications) => {
    if (isNotificationsOn) {
      setTaskNotifications((prevState) => ({
        ...prevState,
        [task]: !prevState[task],
      }));
    }
  };

  const prevPage =
    user.role === "Farm Manager"
      ? "/profile/profile-user-management"
      : "/profile";
  const nextPage = "/profile/profile-languages";

  return (
    <div className={styles["profile-notifications"]}>
      {!isMobile ? (
        <ProfileHeading
          pageTitle="Notifications"
          prevPage={prevPage}
          nextPage={nextPage}
        />
      ) : (
        <div className={styles["profile-notifications__header"]}>
          <img
            src={bell}
            alt="Profile Icon"
            className={styles["profile-notifications__header__image"]}
          />
          <h3 className={styles["profile-notifications__header__head"]}>
            Notifications
          </h3>
          <img
            src={closeIcon}
            alt="Close"
            className={styles["profile-notifications__close-btn"]}
            onClick={onClosed}
          />
        </div>
      )}
      <div className={styles["profile-notifications__tasks"]}>
        <h2 className={styles["profile-notifications__title"]}> TASKS </h2>
        <ul className={styles["profile-notifications__tasks__items"]}>
          <li>
            <img src={notification} alt="Notification Icon" />
            Overdue Tasks
            <ToggleNotifications
              checked={taskNotifications.overdue}
              disabled={!isNotificationsOn}
              onChange={() => handleTaskToggle("overdue")}
            />
          </li>
          <li>
            <img src={notification} alt="Notification Icon" />
            Canceled Tasks
            <ToggleNotifications
              checked={taskNotifications.canceled}
              disabled={!isNotificationsOn}
              onChange={() => handleTaskToggle("canceled")}
            />
          </li>
          <li>
            <img src={notification} alt="Notification Icon" />
            Upcoming Tasks
            <ToggleNotifications
              checked={taskNotifications.upcoming}
              disabled={!isNotificationsOn}
              onChange={() => handleTaskToggle("upcoming")}
            />
          </li>
          <li>
            <img src={notification} alt="Notification Icon" />
            Completed Tasks
            <ToggleNotifications
              checked={taskNotifications.completed}
              disabled={!isNotificationsOn}
              onChange={() => handleTaskToggle("completed")}
            />
          </li>
        </ul>
      </div>
    </div>
  );
}
