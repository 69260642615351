import styles from "../../styles/FarmSingleFieldItem.module.scss";
import Area from "../../assets/area.png";

import { useContext, useEffect } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";

export default function SingleFieldItems() {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    // Assuming 'newTaskForm' as the section for loading locale data specific to this form
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US"; // default to English US if none set
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  return (
    <div className={styles["accordion-items"]}>
      <div className={styles["accordion-items__group"]}>
        <div className="form__input">
          <label className={styles["accordion-items__item"]}>
            <Trans id="singleFieldItems.fieldName">Field Name</Trans>
          </label>
          <input type="text" name="field-name" value="Ausleben-Nord" />
        </div>
        <div className="form__input">
          <label className={styles["accordion-items__item"]}>
            <Trans id="singleFieldItems.area">Area</Trans>
          </label>
          <input type="text" name="area" value="Ausleben Nord" disabled />
        </div>
      </div>
      <div className="form__input">
        <label className={styles["accordion-items__item"]}>
          <Trans id="singleFieldItems.description">Description</Trans>
        </label>
        <input type="text" name="description" value="231984 (NZ)" />
      </div>
      <div className={styles["accordion-items__coordinates"]}>
        <img src={Area} />
        <div>
          <div className="form__input">
            <label className={styles["accordion-items__item"]}>
              <Trans id="singleFieldItems.coordinates">
                Coordinates/Polygon
              </Trans>
            </label>
            <input
              type="text"
              name="coordinates"
              value="[234242423425...]"
              disabled
            />
          </div>
          <span>
            <div className="form__input">
              <label className={styles["accordion-items__item"]}>
                <Trans id="singleFieldItems.size">Size (ha)</Trans>
              </label>
              <input type="text" name="size" value="213" disabled />
            </div>
            <div className="form__input">
              <label className={styles["accordion-items__item"]}>
                <Trans id="singleFieldItems.size">Size (ha)</Trans>
              </label>
              <input type="text" name="area-type" value="arable" disabled />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
}
