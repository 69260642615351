import { Outlet } from "react-router-dom";
import Header from "../components/Header";

export default function Root() {
  return (
    <>
      <div className="container">
        <Header />
        <Outlet />
      </div>
    </>
  );
}

// if (isSuccessOpen && isMobile) {
    //   return (
    //     <div className={styles["success-modal-content"]}>
    //       <img src={success} alt="" />
    //       <h2 className={styles["success-modal__title"]}>User Added!</h2>
    //       <p className={styles["success-modal__message"]}>
    //         You have successfully created user <span>Bridget Jones.</span>
    //       </p>
    //       <FormButton children="Got it" action={handleCloseSuccessModal} />
    //     </div>
    //   );
    // }
    // if (isDeleteModalOpen && isMobile) {
    //   return (
    //     <div className={styles["success-modal-content"]}>
    //       <img src={success} alt="" />
    //       <h2 className={styles["success-modal__title"]}>User deleted!</h2>
    //       <p className={styles["success-modal__message"]}>
    //         You have successfully deleted user <span>{deletedUserName}</span>
    //       </p>
    //       <FormButton children="Got it" action={handleCloseDeleteModal} />
    //     </div>
    //   );
    // }
