import { useState,useContext,useEffect } from "react"
import ProfileHeading from "./ProfileHeading";
import CustomRadioButton from "./CustomRadioButton";
import styles from "../../components/profile/ProfileLanguages.module.scss";
import language from "../../assets/icons/profile/language.svg";
import lang from "../../assets/icons/profile/language2.svg";
import useIsMobile from "../../hooks/useIsMobile";
import closeIcon from "../../assets/icons/close.svg";

interface ProfileLanguagesProps {
  selectedLanguage: string;
  onLanguageChange: (value: string) => void;
  onClosed: () => void;
}

const ProfileLanguages: React.FC<ProfileLanguagesProps> = ({
  selectedLanguage,
  onLanguageChange,
  onClosed,
}) => {
  const languages = [
    { label: "English (US)", img: lang },
    { label: "English (UK)", img: lang },
  ];

  const isMobile = useIsMobile();

  return (
    <div className={styles["profile-languages"]}>
      {isMobile ? (
        <div className={styles["profile-languages__header"]}>
          <img
            src={language}
            alt="Profile Icon"
            className={styles["profile-languages__header__image"]}
          />
          <h3 className={styles["profile-languages__header__head"]}>
            Languages
          </h3>
          <img
            src={closeIcon}
            alt="Close"
            className={styles["profile-languages__close-btn"]}
            onClick={onClosed}
          />
        </div>
      ) : (
        <ProfileHeading
          pageTitle="Language"
          prevPage="/profile/profile-notifications"
          nextPage={null}
        />
      )}
      <div className={styles["profile-languages__suggested"]}>
        <h2 className={styles["profile-languages__title"]}> SUGGESTED </h2>
        <ul className={styles["profile-languages__suggested__items"]}>
          {languages.map((language) => (
            <li key={language.label}>
              <CustomRadioButton
                img={language.img}
                label={language.label}
                checked={selectedLanguage === language.label}
                onChange={() => onLanguageChange(language.label)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProfileLanguages;
