import { CSSProperties, ReactNode } from "react";

import styles from "./SectionContentGroup.module.scss";

interface SectionContentGroupType {
  icon?: string;
  title: string;
  children: ReactNode;
  actions?: ReactNode;
  customCSS?: CSSProperties;
}

export default function SectionContentGroup({
  icon,
  title,
  children,
  actions,
  customCSS,
}: SectionContentGroupType) {
  return (
    <>
      <div className={styles["section-content-group"]} style={customCSS}>
        <div className={styles["section-content-group__heading"]}>
          <h3 className={styles["section-content-group__heading__title"]}>
            {icon && <img src={icon} alt={title} />} {title}
          </h3>
          {actions && (
            <div className={styles["section-content-group__heading__actions"]}>
              {actions}
            </div>
          )}
        </div>
        {children}
      </div>
    </>
  );
}
