import React from "react";
import RecommendationItem from "./RecommendationItem";
import styles from "./RecommendationList.module.scss";

export default function RecommendationsList({ recommendations }: { recommendations: { title: string; due: string; }[] }) {
  return (
    <div className={styles.recommendationsList}>
      {recommendations.map((rec, index) => (
        <RecommendationItem key={index} title={rec.title} due={rec.due} />
      ))}
    </div>
  );
}
