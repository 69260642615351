import React, { createContext, useContext, useState, useEffect } from "react";

interface UserContextProps {
  user: {
    name: string;
    lastName: string;
    farm: string;
    role: string;
  };
  setUser: (user: {
    name: string;
    lastName: string;
    farm: string;
    role: string;
  }) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const storedUser = localStorage.getItem("user");
  const initialUser = storedUser
    ? JSON.parse(storedUser)
    : {
        name: "",
        lastName: "",
        farm: "",
        role: "",
      };

  const [user, setUser] = useState(initialUser);

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    }
  }, [user]); 

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
