import { useContext, useEffect } from "react";
import { LocaleContext } from "../useContext/localeContext";
import { NavLink } from "react-router-dom";

import logo from "../assets/logo.png";
import homeIcon from "../assets/icons/heading/headingHome.svg";
import profileIcon from "../assets/icons/profile.svg";
import filesIcon from "../assets/icons/heading/headingTasks.svg";
import starIcon from "../assets/icons/star.svg";

import styles from "../styles/Header.module.scss";

import {Trans} from "@lingui/react";

export default function Header() {
  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem('appLocale') || 'en-US';
    setLocale(currentLocale, 'header');
  }, [setLocale]);

  return (
    <>
      <header className={styles.header}>
        <nav className={styles.nav}>
          <NavLink className={styles.nav__logo} to="/">
            <img src={logo} alt="Farmers App" />
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/"
          >
            <img src={homeIcon} alt="Home" />
            <span><Trans id="header.home">Home</Trans></span>
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/tasks"
          >
            <img src={filesIcon} alt="files" />
            <span><Trans id="header.tasks">Tasks</Trans></span>
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/task-recommendations"
          >
            <img src={starIcon} alt="Home" />
            <span>Inputs</span>
          </NavLink>
          <NavLink
            className={({ isActive, isPending, isTransitioning }) => {
              return [
                styles.nav__link,
                isActive ? `${styles["nav__link--is-active"]}` : "",
                isPending ? `${styles["nav__link--is-pending"]}` : "",
                isTransitioning
                  ? `${styles["nav__link--is-transitioning"]}`
                  : "",
              ].join(" ");
            }}
            to="/profile"
          >
            <img src={profileIcon} alt="files" />
            <span><Trans id="header.profile">Profile</Trans></span>
          </NavLink>
        </nav>
      </header>
    </>
  );
}
