import { useContext, useEffect } from "react";
import { useOAuth2 } from "../hooks/useOAuth2";
import { ThemeContext } from "../useContext/context";
import { useNavigate } from "react-router-dom";

import logo from "../assets/logo.svg";
import loginBackground from "../assets/login-background.jpg";

import styles from "../styles/routes/login.module.scss";

export default function Login() {
  const context = useContext(ThemeContext);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  async function userLogin() {
    const accessToken = await useOAuth2();

    console.log("accessToken", accessToken);

    sessionStorage.setItem("accessToken", JSON.stringify(accessToken));
    
    navigate("/")

  }

  return (
    <>
      <main
        className={styles.main}
        style={{
          margin: "-1.6rem",
          backgroundImage: `url(${loginBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <section className={styles.main__section}>
          <div className={styles.main__heading}>
            <img
              className={styles.main__logo}
              src={logo}
              alt="Digital Farmer"
            />
            <h1 className={styles.main__heading__title}>
              Welcome to Digital Farmer
            </h1>
            <p className={`subtitle ${styles.main__heading__title}`}>
              Sign In to your account.
            </p>
          </div>
          <div className={styles.main__login}>
            <form action="" className="main__login-form form">
              <div className="form__group">
                  <h2 className="form__group--title"> Single Sign-On </h2>
                  <p className="form__group--description"> Helping you achieve higher efficiency, 
                  improve sustainability, and increase production. </p>
              </div>
              <div className="form__group">
                <button 
                  className="form__button" 
                  type="submit"
                  onClick={userLogin} 
                >
                  Sign In with SSO
                </button>
              </div>
              <div className="form__info">
                <a
                  href="#"
                  className="form__info-link form__info-link--underline"
                >
                  Forgot Password?
                </a>
              </div>
            </form>
          </div>
        </section>
      </main>
    </>
  );
}
