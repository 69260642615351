import SectionContentGroup from "../components/section/SectionContentGroup";
import {
  Form,
  FormButton,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
} from "../components/form/Form";

import editIcon from "../assets/icons/edit.svg";
import trashIcon from "../assets/icons/trash.svg";

const buttonWithIconCSS = {
  flexShrink: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "auto",
  width: "3.9rem",
  height: "3.9rem",
  backgroundColor: "transparent",
  border: "1px solid var(--bd-clr-primary)",
  borderRadius: "0.8rem",
  cursor: "pointer",
};

export default function FieldDetailsInformation() {
  return (
    <>
      <SectionContentGroup
        title="Field Details"
        actions={
          <>
            <FormButton
              type="button"
              customCSS={{
                margin: 0,
                padding: "0.8rem 3rem",
              }}
            >
              Save
            </FormButton>
            <button style={buttonWithIconCSS}>
              <img src={editIcon} alt="Edit Field Details" />
            </button>
          </>
        }
      >
        <Form>
          <FormGroup isRow={true}>
            <FormInput id="field-name" name="field-name" label="FIELD NAME*" />
            <FormInput id="field-area" name="field-area" label="AREA*" />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormTextarea
              id="field-area-desctiption"
              name="field-area-desctiption"
              label="DESCRIPTION*"
            />
            <FormGroup isRow={true}>
              <FormInput
                id="start-date"
                name="start-date"
                type="date"
                label="Start Date"
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
              />
              <FormInput
                id="end-date"
                name="end-date"
                type="date"
                label="End Date"
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
              />
            </FormGroup>
          </FormGroup>
          <FormTextarea
            id="field-area-coordinates"
            name="field-area-coordinates"
            label="Coordinates/Polygon*"
            placeholder="[80.96632957690962,
                              26.777063336375846...]"
          />
          <FormGroup isRow={true}>
            <FormInput id="field-size" name="field-size" label="SIZE (HA)*" />
            <FormSelect
              id="field-area-type"
              name="field-area-type"
              label="AREA TYPE*"
              selectOptions={["Option 1", "Option 2", "Option 3"]}
            />
          </FormGroup>
        </Form>
      </SectionContentGroup>
      <SectionContentGroup
        title="Crop Zone Details"
        actions={
          <>
            <button style={buttonWithIconCSS}>
              <img src={editIcon} alt="Edit Crop Zone Details" />
            </button>
            <button style={buttonWithIconCSS}>
              <img src={trashIcon} alt="Delete Crop Zone Details" />
            </button>
          </>
        }
      >
        <Form>
          <FormGroup isRow={true}>
            <FormInput
              id="crop-zone-crop"
              name="crop-zone-crop"
              label="CROP*"
            />
            <FormInput
              id="crop-zone-season"
              name="crop-zone-season"
              label="SEASON*"
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormInput
              id="crop-zone-variety"
              name="crop-zone-variety"
              label="VARIETY*"
            />
            <FormInput
              id="crop-zone-production-purpose"
              name="crop-zone-production-purpose"
              label="PRODUCTION PURPOSE*"
            />
          </FormGroup>
        </Form>
      </SectionContentGroup>
    </>
  );
}
