import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useMatch,
  useNavigate,
} from "react-router-dom";
import ProfileSettings from "./Settings";
import Heading from "../page/Heading";
import styles from "../../styles/routes/profile.module.scss";
import Area from "../../assets/area.png";
import ViewProfile from "./ViewProfile";
import ProfileNotifications from "./ProfileNotifications";
import ProfileLanguages from "./ProfileLanguages";
import UserManagement from "./UserManagement";
import Modal from "../modal/Modal";
import newUserIcon from "../../assets/icons/profile/NewUser.svg";
import { useUser } from "../../useContext/UserContext";
import NewUser from "./NewUser";
import { FormButton } from "../form/Form";
import success from "../../assets/icons/profile/success.svg";
import useIsMobile from "../../hooks/useIsMobile";
import deleteIcon from "../../assets/icons/profile/delete.svg";
import danger from "../../assets/icons/profile/danger.svg";
import profileHeading from "../../assets/icons/profile/profileHeading.svg";

interface user {
  name: string;
  role: string;
  hasTasks: boolean;
  icon: string;
}

const usersData: user[] = [
  {
    name: "Kevin Bacon",
    role: "Farm operator",
    hasTasks: true,
    icon: danger,
  },
  {
    name: "Jane Doe",
    role: "Agronomist",
    hasTasks: false,
    icon: deleteIcon,
  },
  {
    name: "Gary Oldman",
    role: "Farm Operator",
    hasTasks: false,
    icon: deleteIcon,
  },
];
import { Trans } from '@lingui/react';
import profileIcon from "../../assets/icons/profile/profileHeading.svg";

export default function LayoutProfile() {
  const { user } = useUser();
  const [users, setUsers] = useState(usersData);
  const [isNotificationsOn, setIsnotificationsOn] = useState<boolean>(true);
  const [selectedLanguages, setSelectedLanguages] =
    useState<string>("English (US)");
  const [isNewUserModalOpen, setIsNewUserModalOpen] = useState<boolean>(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [deletedUserName, setDeletedUserName] = useState<string>("");
  const isMobile = useIsMobile();
  const [activeMenuItem, setActiveMenuItem] = useState<string | null>(null);
  const navigate = useNavigate();

  const onLanguageChange = (value: string) => {
    setSelectedLanguages(value);
  };

  const onNotificationToggle = (value: boolean) => {
    setIsnotificationsOn(value);
  };

  const userManagementLocation = useMatch("/profile/profile-user-management");

  const handleUser = () => {
    setIsNewUserModalOpen(true);
  };

  const handleAddUser = () => {
    setIsNewUserModalOpen(false);
    setIsSuccessOpen(true);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  const handleCloseMenu = () => {
    setActiveMenuItem(null);
    navigate(-1);
  };

  const handleDeleteUser = (userName: string) => {
    setUsers((prevUsers) => prevUsers.filter((user) => user.name !== userName));
    setDeletedUserName(userName);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  useEffect(() => {
    if (activeMenuItem && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [activeMenuItem]);

  useEffect(() => {
    const pathToMenuItem: { [key: string]: string } = {
      "/profile/view-profile": "Profile",
      "/profile/profile-notifications": "Notifications",
      "/profile/profile-languages": "Languages",
      "/profile/profile-user-management": "User Management",
    };

    setActiveMenuItem(pathToMenuItem[location.pathname] || null);
  }, [location.pathname]);

  const renderMenuContent = () => {
    if (isSuccessOpen && isMobile) {
      return (
        <div className={styles["success-modal-content"]}>
          <img src={success} alt="" />
          <h2 className={styles["success-modal__title"]}>User Added!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully created user <span>Bridget Jones.</span>
          </p>
          <FormButton children="Got it" action={handleCloseSuccessModal} />
        </div>
      );
    }
    if (isDeleteModalOpen && isMobile) {
      return (
        <div className={styles["success-modal-content"]}>
          <img src={success} alt="" />
          <h2 className={styles["success-modal__title"]}>User deleted!</h2>
          <p className={styles["success-modal__message"]}>
            You have successfully deleted user <span>{deletedUserName}</span>
          </p>
          <FormButton children="Got it" action={handleCloseDeleteModal} />
        </div>
      );
    }
    switch (activeMenuItem) {
      case "Profile":
        return <ViewProfile onClosed={handleCloseMenu} />;
      case "Notifications":
        return (
          <ProfileNotifications
            isNotificationsOn={isNotificationsOn}
            onNotificationToggle={onNotificationToggle}
            onClosed={handleCloseMenu}
          />
        );
      case "Languages":
        return (
          <ProfileLanguages
            selectedLanguage={selectedLanguages}
            onLanguageChange={onLanguageChange}
            onClosed={handleCloseMenu}
          />
        );
      case "User Management":
        return (
          <UserManagement
            users={users}
            setUsers={setUsers}
            onClosed={() => setActiveMenuItem(null)}
            onAddUserClick={() => setActiveMenuItem("New User")}
            onDeleteUser={handleDeleteUser}
          />
        );
      case "New User":
        return (
          <NewUser
            handleAddUser={() => {
              setIsSuccessOpen(true);
              setActiveMenuItem("User Management");
            }}
            onClose={() => setActiveMenuItem("User Management")}
          />
        );
      default:
        return null;
    }
  };

  return (
    <main className={styles.main}>
      <Heading
        pageTitle="Settings"
        pageIcon={profileHeading}
        {...(user.role === "Farm Manager" && userManagementLocation && !isMobile
          ? { actionTitle: "New User", action: handleUser }
          : {})}
      />

      <div className={styles["main__body"]}>
        <div className={styles["main__body__sidebar"]}>
          <img
            className={styles["main__body__sidebar__image"]}
            src={Area}
            alt="User area"
          />
          <h1>
            {user.name} {user.lastName}
          </h1>
          <h2>{user.role}</h2>
          <ProfileSettings
            selectedLanguage={selectedLanguages}
            isNotificationsOn={isNotificationsOn}
            onNotificationToggle={onNotificationToggle}
            isMobile={isMobile}
            onMenuClick={(item) => setActiveMenuItem(item)}
          />
        </div>

        {isMobile && activeMenuItem ? (
          <div className={styles["full-screen"]}>{renderMenuContent()}</div>
        ) : (
          <Routes>
            {isMobile ? (
              <>
                {activeMenuItem === null && (
                  <Route path="/profile" element={<Navigate to="" replace />} />
                )}
                <Route
                  path="view-profile"
                  element={<ViewProfile onClosed={handleCloseMenu} />}
                />
                {user.role === "Farm Manager" && (
                  <Route
                    path="/profile/profile-user-management"
                    element={
                      <UserManagement
                        users={users}
                        setUsers={setUsers}
                        onClosed={handleCloseMenu}
                        onAddUserClick={() => setActiveMenuItem("New User")}
                        onDeleteUser={handleDeleteUser}
                      />
                    }
                  />
                )}
              </>
            ) : (
              <>
                <Route
                  path="/"
                  element={<ViewProfile onClosed={handleCloseMenu} />}
                />
                {user.role === "Farm Manager" && (
                  <Route
                    path="profile-user-management"
                    element={
                      <UserManagement
                        users={users}
                        setUsers={setUsers}
                        onClosed={handleCloseMenu}
                        onAddUserClick={() => setActiveMenuItem("New User")}
                        onDeleteUser={handleDeleteUser}
                      />
                    }
                  />
                )}
                <Route
                  path="profile-notifications"
                  element={
                    <ProfileNotifications
                      isNotificationsOn={isNotificationsOn}
                      onNotificationToggle={onNotificationToggle}
                      onClosed={handleCloseMenu}
                    />
                  }
                />
                <Route
                  path="profile-languages"
                  element={
                    <ProfileLanguages
                      selectedLanguage={selectedLanguages}
                      onLanguageChange={onLanguageChange}
                      onClosed={handleCloseMenu}
                    />
                  }
                />
              </>
            )}
          </Routes>
        )}

        <Modal
          isOpen={isNewUserModalOpen}
          onClose={() => setIsNewUserModalOpen(false)}
          headingTitle="New user"
          headingIcon={newUserIcon}
        >
          <NewUser
            handleAddUser={handleAddUser}
            onClose={() => setIsNewUserModalOpen(false)}
          />
        </Modal>

        <Modal
          headingTitle=""
          isOpen={isSuccessOpen}
          onClose={handleCloseSuccessModal}
          size="sm"
          showCloseButton={false}
        >
          <div className={styles["success-modal-content"]}>
            <img src={success} alt="" />
            <h2 className={styles["success-modal__title"]}>User Added!</h2>
            <p className={styles["success-modal__message"]}>
              You have successfully created user <span>Bridget Jones.</span>
            </p>
            <FormButton children="Got it" action={handleCloseSuccessModal} />
          </div>
        </Modal>

        <Modal
          headingTitle=""
          isOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
          size="sm"
          showCloseButton={false}
        >
          <div className={styles["success-modal-content"]}>
            <img src={success} alt="" />
            <h2 className={styles["success-modal__title"]}>User Deleted!</h2>
            <p className={styles["success-modal__message"]}>
              You have successfully deleted user <span>{deletedUserName}</span>
            </p>
            <FormButton children="Got it" action={handleCloseDeleteModal} />
          </div>
        </Modal>
      </div>
    </main>
  );
}

