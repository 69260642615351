import styles from "../../styles/FarmNewField.module.scss";
import type { SetupProps } from "../../types";
import Area from "../../assets/area.png";

import React, { useContext, useEffect, useState, ChangeEvent } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";
import { useNavigate } from "react-router-dom";
import alertIcon from "../../assets/icons/alert.svg";

import useIsMobile from "../../hooks/useIsMobile";

import {
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  FormSelect,
  FormButton,
} from "../form/Form";

type TAddNewFieldProps = SetupProps;

interface FormData {
  fieldName: string;
  area: string;
  description: string;
  validFrom: string;
  validTo: string;
  coordinates: string;
  size: string;
  areaType: string;
}

const AddNewField: React.FC<TAddNewFieldProps> = ({ page, changePage }) => {
  const { setLocale } = useContext(LocaleContext);

  const navigate = useNavigate();

  const [formData, setFormData] = useState<FormData>({
    fieldName: "",
    area: "",
    description: "",
    validFrom: "",
    validTo: "",
    coordinates: "",
    size: "",
    areaType: "",
  });

  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [topError, setTopError] = useState<string>("");

  const validateForm = (): Record<string, string> => {
    const errors: Record<string, string> = {};

    if (!formData.fieldName.trim()) {
      errors.fieldName = "Field name is required.";
    }
    if (!formData.area.trim()) {
      errors.area = "Area is required.";
    }
    if (!formData.description.trim()) {
      errors.description = "Description is required.";
    }
    if (!formData.validFrom.trim()) {
      errors.validFrom = "Valid From is required.";
    }
    if (!formData.validTo.trim()) {
      errors.validTo = "Valid To is required.";
    }
    if (formData.validFrom && formData.validTo) {
      const from = new Date(formData.validFrom);
      const to = new Date(formData.validTo);
      if (to < from) {
        errors.validTo =
          "Valid To should be greater than or equal to Valid From.";
      }
    }

    if (!formData.coordinates.trim()) {
      errors.coordinates = "Coordinates are required.";
    }
    if (!formData.size.trim()) {
      errors.size = "Size is required.";
    }
    if (!formData.areaType.trim()) {
      errors.areaType = "Area type is required.";
    }

    return errors;
  };

  useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev: FormData) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCancel = () => {
    window.location.reload();
    changePage({ page, isForward: false });
  };

  const handleAddField = (e: React.FormEvent) => {
    e.preventDefault();

    const errors = validateForm();
    setFieldErrors(errors);
    if (Object.keys(errors).length === 0) {
      setTopError("");
      navigate("/field-information")
      return;
    }

    if (
      Object.keys(errors).length === 1 &&
      errors.validTo ===
        "Valid To should be greater than or equal to Valid From."
    ) {
      setTopError("Valid To should be greater than or equal to Valid From");
    } else {
      setTopError("Populate mandatory fields");
    }
  };

  const isMobile = useIsMobile();

  const firstGroupStyle: React.CSSProperties = isMobile
    ? {
        display: "block",
        marginBottom: "1rem",
      }
    : {
        display: "flex",
        gap: "3rem",
        flexWrap: "wrap",
      };

  const secondGroupStyle: React.CSSProperties = isMobile
    ? {
        display: "block",
      }
    : {
        display: "flex",
        gap: "1rem",
        flexWrap: "wrap",
      };

  const thirdGroupStyle: React.CSSProperties = isMobile
    ? {
        display: "flex",
        gap: "1rem",
      }
    : {
        display: "flex",
        gap: "2rem",
        flexWrap: "wrap",
      };

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        padding: "2rem",
        borderRadius: "2rem",
        border: "1px solid #e3e7eb",
        color: "#999999",
        textTransform: "uppercase",
      }}
    >
      {topError && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "1rem",
            marginBottom: "1rem",
            backgroundColor: "rgba(255, 240, 240, 1)",
            borderRadius: "3px",
            color: "var(--alert-clr)",
            fontWeight: 700,
            textAlign: "center",
            height: "25px",
          }}
        >
          <div>
            <img src={alertIcon} alt="Alert Icon" />
          </div>
          <div>{topError}</div>
        </div>
      )}

      <Form
        customCSS={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
        onSubmit={handleAddField}
      >
        <FormGroup isRow={true} customCSS={firstGroupStyle}>
          <FormInput
            id="field-name"
            name="fieldName"
            label="FIELD NAME*"
            placeholder="Enter field name"
            value={formData.fieldName}
            onChange={handleChange}
            hasError={Boolean(fieldErrors.fieldName)}
            customCSS={{
              flex: "1 1 45%",
              marginBottom: isMobile ? "1rem" : 0,
            }}
          />
          <FormInput
            id="field-area"
            name="area"
            label="AREA*"
            placeholder="Enter area"
            value={formData.area}
            onChange={handleChange}
            hasError={Boolean(fieldErrors.area)}
            customCSS={{
              flex: "1 1 45%",
              marginTop: isMobile ? "1.2rem" : 0,
            }}
          />
        </FormGroup>
        <FormGroup isRow={true} customCSS={secondGroupStyle}>
          <FormTextarea
            id="description"
            name="description"
            label="DESCRIPTION*"
            placeholder="Enter description"
            value={formData.description}
            onChange={handleChange}
            hasError={Boolean(fieldErrors.description)}
            customCSS={{
              marginBottom: isMobile ? "1rem" : "0",
              flex: isMobile ? "1 1 100%" : "2 1 45%",
              resize: "vertical",
              padding: "0.5rem",
              borderRadius: "0.5rem",
            }}
          />
          <div
            style={{
              display: isMobile ? "block" : "flex",
              gap: "1rem",
              flex: isMobile ? "1 1 100%" : "2 1 45%",
            }}
          >
            <FormInput
              id="valid-from"
              name="validFrom"
              type="date"
              label="Valid From*"
              value={formData.validFrom}
              onChange={handleChange}
              hasError={Boolean(fieldErrors.validFrom)}
              customCSS={{
                marginBottom: isMobile ? "1rem" : 0,
                padding: "0.5rem",
                borderRadius: "0.5rem",
                flex: isMobile ? "1 1 auto" : "1 1 45%",
              }}
            />
            <FormInput
              id="valid-to"
              name="validTo"
              type="date"
              label="Valid To*"
              value={formData.validTo}
              onChange={handleChange}
              hasError={Boolean(fieldErrors.validTo)}
              customCSS={{
                padding: "0.5rem",
                borderRadius: "0.5rem",
                flex: isMobile ? "1 1 auto" : "1 1 45%",
              }}
            />
          </div>
        </FormGroup>

        {isMobile ? (
          <>
            <FormGroup isRow={false} customCSS={thirdGroupStyle}>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={Area}
                  alt="Area"
                  style={{
                    width: "100%",
                    maxWidth: "40rem",
                    borderRadius: "1rem",
                    objectFit: "cover",
                  }}
                />
              </div>
              <FormInput
                id="coordinates"
                name="coordinates"
                label="Coordinates/Polygon*"
                placeholder="[80.96632957690962, 26.777063336375846...]"
                value={formData.coordinates}
                onChange={handleChange}
                hasError={Boolean(fieldErrors.coordinates)}
                customCSS={{
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                  width: "100%",
                  minHeight: "8rem",
                  height: "auto",
                  boxSizing: "border-box",
                }}
              />
            </FormGroup>

            <FormGroup
              isRow={false}
              customCSS={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
              }}
            >
              <FormInput
                id="size"
                name="size"
                label="SIZE (HA)*"
                placeholder="Enter size in hectares"
                value={formData.size}
                onChange={handleChange}
                hasError={Boolean(fieldErrors.size)}
                customCSS={{
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
              <FormSelect
                id="area-type"
                name="areaType"
                label="AREA TYPE*"
                selectOptions={["Option 1", "Option 2", "Option 3"]}
                value={formData.areaType}
                onChange={handleChange}
                hasError={Boolean(fieldErrors.areaType)}
                customCSS={{
                  width: "100%",
                  marginTop: "0.5rem",
                }}
              />
            </FormGroup>
          </>
        ) : (
          <FormGroup isRow={true} customCSS={thirdGroupStyle}>
            <div
              style={{
                flex: "1 1 40%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "0",
              }}
            >
              <img
                src={Area}
                alt="Area"
                style={{
                  width: "100%",
                  maxWidth: "40rem",
                  borderRadius: "1rem",
                  objectFit: "cover",
                }}
              />
            </div>
            <div
              style={{
                flex: "1 1 55%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <FormInput
                id="coordinates"
                name="coordinates"
                label="Coordinates/Polygon*"
                placeholder="[80.96632957690962, 26.777063336375846...]"
                value={formData.coordinates}
                onChange={handleChange}
                hasError={Boolean(fieldErrors.coordinates)}
                customCSS={{
                  padding: "0.5rem",
                  borderRadius: "0.5rem",
                }}
              />
              <FormGroup
                isRow={true}
                customCSS={{
                  display: "flex",
                  gap: "2rem",
                  flexDirection: "row",
                }}
              >
                <FormInput
                  id="size"
                  name="size"
                  label="SIZE (HA)*"
                  placeholder="Enter size in hectares"
                  value={formData.size}
                  onChange={handleChange}
                  hasError={Boolean(fieldErrors.size)}
                  customCSS={{
                    flex: "1 1 50%",
                    marginLeft: "1rem",
                    marginBottom: "0",
                  }}
                />
                <FormSelect
                  id="area-type"
                  name="areaType"
                  label="AREA TYPE*"
                  selectOptions={["Option 1", "Option 2", "Option 3"]}
                  value={formData.areaType}
                  onChange={handleChange}
                  hasError={Boolean(fieldErrors.areaType)}
                  customCSS={{ flex: "1 1 50%" }}
                />
              </FormGroup>
            </div>
          </FormGroup>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1rem",
            marginTop: "4rem",
            flexDirection: "row",
          }}
        >
          <FormButton
            type="button"
            action={handleCancel}
            isTransparent={true}
            customCSS={{ width: "100%", height: "100%", padding: "1.5rem" }}
          >
            <strong>Cancel</strong>
          </FormButton>
          <FormButton
            type="submit"
            customCSS={{ width: "100%", height: "100%", padding: "1.5rem" }}
          >
            Add Field
          </FormButton>
        </div>
      </Form>
    </div>
  );
};

export default AddNewField;
