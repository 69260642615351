import Notification from "../notification/Notification";
import styles from "../../styles/Heading.module.scss";
import settings from "../../assets/icons/profile/user.svg";

export default function Heading({
  pageTitle,
  pageIcon,
  actionTitle,
  action,
}: {
  pageTitle: React.ReactNode;
  pageIcon?: string;
  actionTitle?: string;
  action?: () => void;
}) {
  return (
    <>
      <div className={styles.heading}>
        {settings && (
          <div className={styles["heading__icon-wrapper"]}>
            <img src={pageIcon} alt={settings} />
          </div>
        )}
        <h1 className={styles.heading__title}>{pageTitle}</h1>
        <div className={styles.heading__actions}>
          {actionTitle && action && (
            <button
              className={styles.heading__button}
              type="button"
              onClick={action}
            >
              {actionTitle}
            </button>
          )}
          <Notification />
        </div>
      </div>
    </>
  );
}
