import React, { useState } from "react";
import styles from "./RecommendationItem.module.scss";
import dropdownIcon from "../../assets/icons/tasks/dropdown.svg";
import closeupIcon from "../../assets/icons/tasks/closeup.svg";
import Modal from "../modal/Modal";
import TaskAssigned from "./TaskAssigned";
import DeleteTaskAssigned from "./DeleteTaskAssigned";
import declineIcon from "../../assets/icons/tasks/declineRecommendation.svg";
import useIsMobile from "../../hooks/useIsMobile";

type RecommendationStatus = "none" | "accepted" | "declined";

export default function RecommendationItem({
  title,
  due,
}: {
  title: string;
  due: string;
}) {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [status, setStatus] = useState<RecommendationStatus>("none");
  const [acceptModalOpen, setAcceptModalOpen] = useState<boolean>(false);
  const [declineModalOpen, setDeclineModalOpen] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const toggleExpand = () => {
    setExpanded((prev: boolean) => !prev);
  };

  return (
    <div
      className={`${styles.recommendationItem} ${
        expanded ? styles.expanded : ""
      }`}
    >
      {isMobile ? (
        <div className={styles.recommendationItem__topRowMobile}>
        <div className={styles.mobileRowContent}>
          <div className={styles.mobileRowOne}>
            <span className={styles.recommendationItem__title}>{title}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <span className={styles.recommendationItem__due}>DUE {due}</span>
            <div className={styles.mobileRowButtons}>
              {status === "none" && (
                <>
                  <button className={styles.acceptButton} onClick={() => setAcceptModalOpen(true)}>
                    ACCEPT
                  </button>
                  <button className={styles.declineButton} onClick={() => setDeclineModalOpen(true)}>
                    DECLINE
                  </button>
                </>
              )}
              {status === "accepted" && (
                <span className={styles.acceptedText}>ACCEPTED</span>
              )}
              {status === "declined" && (
                <span className={styles.declinedText}>DECLINED</span>
              )}
            </div>
          </div>
        </div>
        <div>
          <button className={styles.dropdownButton} onClick={toggleExpand}>
            <img
              src={expanded ? closeupIcon : dropdownIcon}
              alt={expanded ? "Less options" : "More options"}
            />
          </button>
        </div>
      </div>
      ) : (
        <div className={styles.recommendationItem__topRow}>
          <div className={styles.recommendationItem__info}>
            <span className={styles.recommendationItem__title}>{title}</span>
            <span className={styles.recommendationItem__due}>DUE {due}</span>
          </div>
          <div className={styles.recommendationItem__actions}>
            {status === "none" && (
              <>
                <button
                  className={styles.acceptButton}
                  onClick={() => setAcceptModalOpen(true)}
                >
                  ACCEPT
                </button>
                <button
                  className={styles.declineButton}
                  onClick={() => setDeclineModalOpen(true)}
                >
                  DECLINE
                </button>
              </>
            )}
            {status === "accepted" && (
              <span className={styles.acceptedText}>ACCEPTED</span>
            )}
            {status === "declined" && (
              <span className={styles.declinedText}>DECLINED</span>
            )}
            <button className={styles.dropdownButton} onClick={toggleExpand}>
              <img
                src={expanded ? closeupIcon : dropdownIcon}
                alt={expanded ? "Less options" : "More options"}
              />
            </button>
          </div>
        </div>
      )}
      {expanded && (
        <div className={styles.recommendationItem__content}>
          <hr className={styles.divider} />
          <div className={styles.taskColumns}>
            <div className={styles.taskColumn}>
              <table className={styles.taskTable}>
                <tbody>
                  <tr>
                    <th className={styles.taskLabel}>TASK NAME</th>
                    <td className={styles.taskValue}>Fertilization</td>
                    <th className={styles.taskLabel}>PRIORITY</th>
                    <td className={styles.taskValue}>Medium</td>
                  </tr>
                  <tr>
                    <th className={styles.taskLabel}>TASK TYPE</th>
                    <td className={styles.taskValue}>Fertilization</td>
                    <th className={styles.taskLabel}>Start/End Date</th>
                    <td className={styles.taskValue}>_</td>
                  </tr>
                </tbody>
              </table>
              <div className={styles.taskDescription}>
                <span className={styles.descriptionLabel}>DESCRIPTION</span>
                <span className={styles.descriptionValue}>...</span>
              </div>
            </div>
            <div className={styles.taskColumn}>
              <table className={styles.taskTable}>
                <tbody>
                  <tr>
                    <th className={styles.taskLabel}>TASK NAME</th>
                    <td className={styles.taskValue}>Irrigation</td>
                    <th className={styles.taskLabel}>PRIORITY</th>
                    <td className={styles.taskValue}>Low</td>
                  </tr>
                  <tr>
                    <th className={styles.taskLabel}>TASK TYPE</th>
                    <td className={styles.taskValue}>Irrigation</td>
                    <th className={styles.taskLabel}>Start/End Date</th>
                    <td className={styles.taskValue}>_</td>
                  </tr>
                </tbody>
              </table>
              <div className={styles.taskDescription}>
                <span className={styles.descriptionLabel}>DESCRIPTION</span>
                <span className={styles.descriptionValue}>...</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        headingTitle=""
        size={isMobile ? "xs" : "md"}
        isOpen={acceptModalOpen}
        onClose={() => setAcceptModalOpen(false)}
        showCloseButton={false}
        customStyle={{
          width: "auto",
        }}
      >
        <TaskAssigned
          onGotIt={() => {
            setStatus("accepted");
            setAcceptModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        headingTitle="Decline Recommendation"
        headingSubtitle=""
        headingIcon={declineIcon}
        headingIconStyle={{ backgroundColor: "#FFF0F0" }}
        headingTitleStyle={{ color: "var(--text-clr)",paddingTop: isMobile?"0":"1rem", textAlign: "center" }}
        headingContentStyle={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "2rem",
        }}
        size="xs"
        isOpen={declineModalOpen}
        onClose={() => setDeclineModalOpen(false)}
        showCloseButton={true}
        customStyle={{
          width: isMobile ? "340px" : "auto",
          overflowY: "hidden"
        }}
      >
        <DeleteTaskAssigned
          onConfirm={() => {
            setStatus("declined");
            setDeclineModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
