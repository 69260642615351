import styles from "../../styles/FarmSetupHeading.module.scss";
import arrLeft from "../../assets/icons/arrLeft.svg";
import type { SetupProps } from "../../types";
import { useContext, useEffect } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";

type TFarmInfoProps = SetupProps;

const SetupHeading: React.FC<TFarmInfoProps> = ({ page, changePage }) => {
    const { setLocale } = useContext(LocaleContext);

    useEffect(() => {
    const currentLocale = sessionStorage.getItem("appLocale") || "en-US";
    setLocale(currentLocale, "setupFarm");
  }, [setLocale]);

    return (
        <div className={styles["setup-heading"]}>
            <img
                src={arrLeft}
                alt="Arr Left"
                onClick={() => changePage({page, isForward:false})}
            />
            <span>
                <h2 className={styles["setup-heading__heading"]}><Trans id="setupHeading.checkYourField">Check Your Field Information</Trans></h2>
                <p className={styles["setup-heading__description"]}><Trans id="setupHeading.selectFieldsToImport">Select the fields you would like to import</Trans></p>
            </span>
        </div>
    )
}

export default SetupHeading;
