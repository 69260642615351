import React, { useState, ChangeEvent, useEffect } from "react";
import { Outlet } from "react-router-dom";
import NestedLayout from "../components/page/NestedLayout";

import fieldInformationLinkIcon from "../assets/icons/field-information.svg";
import fieldTasksLinkIcon from "../assets/icons/field-tasks.svg";
import Modal from "../components/modal/Modal";
import CropZoneSuccess from "../components/field/CropZoneSuccess";
import {
  Form,
  FormButton,
  FormGroup,
  FormInput,
  FormSelect,
  FormTextarea,
} from "../components/form/Form";

import tmpImage from "../assets/area.png";

export default function FieldDetails() {
  const [isNewFieldModalOpen, setNewFieldModalOpen] = useState(false);
  const [isNewCropZoneModalOpen, setNewCropZoneModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const [cropZoneName, setCropZoneName] = useState("");
  const [cropVariety, setCropVariety] = useState("Green Smith");
  const [crop, setCrop] = useState("Apple");
  const [cropPurpose, setCropPurpose] = useState("Food Consumption");
  const [cropSeason, setCropSeason] = useState("Summer2024");
  const [cropPerennial, setCropPerennial] = useState("");
  const [seasonStart, setSeasonStart] = useState("");
  const [seasonEnd, setSeasonEnd] = useState("");
  const [productionType, setProductionType] = useState("Conventional");
  const [area, setArea] = useState("");

  const handleSaveCropZone = (e: React.FormEvent) => {
    e.preventDefault();
    const cropZoneData = {
      cropZoneName,
      cropVariety,
      crop,
      cropPurpose,
      cropSeason,
      cropPerennial,
      seasonStart,
      seasonEnd,
      productionType,
      area,
    };
    localStorage.setItem("cropZoneData", JSON.stringify(cropZoneData));
    setNewCropZoneModalOpen(false);
    setIsSuccessModalOpen(true);
  };

  return (
    <>
      <NestedLayout
        pageTitle="Field Details"
        pageIcon={fieldInformationLinkIcon}
        actionTitle="New Field"
        action={() => {
          setNewFieldModalOpen(true);
        }}
        optionalActionTitle="New Crop Zone"
        optionalAction={() => {
          setNewCropZoneModalOpen(true);
        }}
        navLinks={[
          {
            icon: fieldInformationLinkIcon,
            label: "Field Information",
            link: "field-information",
          },
          {
            icon: fieldTasksLinkIcon,
            label: "Field Tasks",
            link: "field-tasks",
          },
        ]}
      >
        <Outlet />
      </NestedLayout>
      <Modal
        headingTitle="Add New Field"
        headingSubtitle="Add a new field to your Farm"
        isOpen={isNewFieldModalOpen}
        onClose={() => {
          setNewFieldModalOpen(false);
        }}
      >
        <Form>
          <FormGroup isRow={true}>
            <FormInput
              id="new-field-name"
              name="new-field-name"
              label="FIELD NAME*"
            />
            <FormInput
              id="new-field-area"
              name="new-field-area"
              label="AREA*"
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormTextarea
              id="new-field-description"
              name="new-field-description"
              label="DESCRIPTION"
            />
            <FormGroup isRow={true}>
              <FormInput
                id="new-field-valid-from"
                name="new-field-valid-from"
                type="date"
                label="VALID FROM*"
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
              />
              <FormInput
                id="new-field-valid-TO"
                name="new-field-valid-TO"
                type="date"
                label="VALID TO"
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
              />
            </FormGroup>
          </FormGroup>
          <FormGroup
            isRow={true}
            customCSS={{
              marginBottom: "10rem",
            }}
          >
            <img
              src={tmpImage}
              style={{
                flex: 0.5,
              }}
            />
            <FormGroup>
              <FormTextarea
                id="new-field-coordinates"
                name="new-field-coordinates"
                label="COORDINATES/POLYGON*"
              />
              <FormGroup isRow={true}>
                <FormInput
                  id="new-field-size"
                  name="new-field-size"
                  label="SIZE (HA)*"
                />
                <FormInput
                  id="new-field-area-type"
                  name="new-field-area-type"
                  label="AREA TYPE*"
                />
              </FormGroup>
            </FormGroup>
          </FormGroup>
          <FormGroup isRow={true}>
            <FormButton
              isTransparent={true}
              type="button"
              action={() => {
                setNewFieldModalOpen(false);
              }}
            >
              Cancel
            </FormButton>
            <FormButton>Add Field</FormButton>
          </FormGroup>
        </Form>
      </Modal>
      <Modal
        headingTitle="Create Crop Zone"
        headingSubtitle="Create your Crop Zone"
        isOpen={isNewCropZoneModalOpen}
        onClose={() => {
          setNewCropZoneModalOpen(false);
        }}
      >
        <Form
          customCSS={{
            border: "1px solid var(--clr-lightgreen)",
            padding: "2rem",
            borderRadius: "var(--form-section-br)",
          }}
        >
          <FormGroup isRow={true}>
            <FormInput
              id="new-crop-zone-name"
              name="new-crop-zone-name"
              label="CROP ZONE NAME*"
              value={cropZoneName}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCropZoneName(e.target.value)
              }
            />
            <FormSelect
              id="new-crop-zone-crop"
              name="new-crop-zone-crop"
              label="VARIETY*"
              value={cropVariety}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setCropVariety(e.target.value)
              }
              selectOptions={["Option 1", "Option 2", "Option 3"]}
              isRequired={true}
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormInput
              id="new-crop-zone-season"
              name="new-crop-zone-season"
              label="CROP*"
              placeholder="Apple"
              value={crop}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCrop(e.target.value)
              }
              isRequired={true}
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormSelect
              id="new-crop-zone-area-type"
              name="new-crop-zone-area-type"
              label="CROP PURPOSE"
              value={cropPurpose}
              selectOptions={["Option 1", "Option 2", "Option 3"]}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setCropPurpose(e.target.value)
              }
            />
            <FormSelect
              id="new-crop-zone-season-type"
              name="new-crop-zone-season-type"
              label="SEASON*"
              value={cropSeason}
              selectOptions={["Option 1", "Option 2", "Option 3"]}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setCropSeason(e.target.value)
              }
              isRequired={true}
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormInput
              id="new-crop-zone-perennial"
              name="new-crop-zone-perennial"
              label="PERENNIAL"
              value={cropPerennial}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setCropPerennial(e.target.value)
              }
              customCSS={{
                height: "50px",
              }}
            />
            <FormGroup isRow={true}>
              <FormInput
                id="new-field-valid-from"
                name="new-field-valid-from"
                type="date"
                label="SEASON START*"
                value={seasonStart}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSeasonStart(e.target.value)
                }
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
              />
              <FormInput
                id="new-field-valid-TO"
                name="new-field-valid-TO"
                type="date"
                label="SEASON END*"
                value={seasonEnd}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSeasonEnd(e.target.value)
                }
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
              />
            </FormGroup>
          </FormGroup>
          <FormGroup isRow={true}>
            <FormSelect
              id="new-crop-zone-season-type"
              name="new-crop-zone-season-type"
              label="PRODUCTION TYPE"
              value={productionType}
              onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                setProductionType(e.target.value)
              }
              selectOptions={["Option 1", "Option 2", "Option 3"]}
            />
            <FormInput
              id="new-field-size"
              name="new-field-size"
              label="AREA"
              value={area}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setArea(e.target.value)
              }
              placeholder="Arable"
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormButton
              isTransparent={true}
              type="button"
              action={() => {
                setNewCropZoneModalOpen(false);
              }}
            >
              Cancel
            </FormButton>
            <FormButton action={handleSaveCropZone}>Save</FormButton>
          </FormGroup>
        </Form>
      </Modal>
      <Modal
        isOpen={isSuccessModalOpen}
        onClose={() => {}}
        headingTitle=""
        headingSubtitle=""
        size="sm"
        showCloseButton={false}
      >
        <CropZoneSuccess
          onGotIt={() => {
            setIsSuccessModalOpen(false);
            window.location.reload();
          }}
        />
      </Modal>
    </>
  );
}
