import styles from "../../components/profile/ToggleNotification.module.scss";

interface ToggleSwitchProps {
  label?: string;
  checked: boolean;
  disabled?: boolean;
  onChange: () => void;
}

const ToggleNotifications: React.FC<ToggleSwitchProps> = ({
  label,
  checked,
  disabled = false,
  onChange,
}) => {
  return (
    <label className={styles.toggleSwitch}>
      {label && <span className={styles.label}>{label}</span>}
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={styles.input}
      />
      <div className={styles.switch} />
    </label>
  );
};

export default ToggleNotifications;
