import styles from "../../styles/FarmSetupInfo.module.scss";
import SetupHeading from "./SetupHeading";
import type { SetupProps } from "../../types";

import { Trans } from "@lingui/react";
import { LocaleContext } from "../../useContext/localeContext";
import { useContext, useEffect } from "react";

type TFarmInfoProps = SetupProps;

const FarmSetupInfo: React.FC<TFarmInfoProps> = ({ page, changePage }) => {

    const { setLocale } = useContext(LocaleContext);

    useEffect(() => {
    const currentLocale = sessionStorage.getItem('appLocale') || 'en-US';
    setLocale(currentLocale, 'setupFarm');
  }, [setLocale]);

    return (
        <div className={styles["setup-info"]}>
            <SetupHeading page={page} changePage={changePage} />
            <form action="" className={styles["setup-info__form"]}>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                    <Trans id="farmSetupInfo.farmName">Farm Name</Trans>
                    </label>
                    <input type="text" name="farm-name" value="Max Mustermann Farm" />
                </div>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                    <Trans id="farmSetupInfo.district">District</Trans>
                    </label>
                    <input type="text" name="district" value="Warsleben" />
                </div>                
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                    <Trans id="farmSetupInfo.description">Description</Trans>
                    </label>
                    <textarea value = "Ausleben Farm NZ"> </textarea>
                </div>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                    <Trans id="farmSetupInfo.location">Location</Trans>
                    </label>
                    <input type="text" name="location" value="Germany" disabled />
                </div>
                <div className="form__input">
                    <label className={styles["accordion-items__item"]}>
                    <Trans id="farmSetupInfo.businessPartner">Your Business Partner</Trans>
                    </label>
                    <input type="text" name="business-partnet" value="test.test@gmail.com" disabled />
                </div>
            </form>
            <button
                onClick={() => changePage({page, isForward:true})}
                className="form__button"
            >
                <Trans id="farmSetupInfo.continueButton">Continue</Trans>
            </button>
        </div>
    )
};

export default FarmSetupInfo;

