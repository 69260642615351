import ProfileHeading from "./ProfileHeading";
import styles from "./ViewProfile.module.scss";
import { useUser } from "../../useContext/UserContext";
import { useEffect } from "react";
import useIsMobile from "../../hooks/useIsMobile";
import profile from "../../assets/icons/profile/profile.svg";
import closeIcon from "../../assets/icons/close.svg";

interface ViewProfileProps {
  onClosed: () => void;
}

const ViewProfile: React.FC<ViewProfileProps> = ({ onClosed }) => {
  const { user, setUser } = useUser();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!user.name || !user.lastName || !user.farm || !user.role) {
      setUser({
        name: user.name || "Samuela",
        lastName: user.lastName || "Farmer",
        farm: user.farm || "Giri Purno",
        role: user.role || "Farm Manager",
      });
    }
  }, [user, setUser]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const isFarmManager = user.role === "Farm Manager";
  const prevPage = null;
  const nextPage = isFarmManager
    ? "/profile/profile-user-management"
    : "/profile/profile-notifications";

  return (
    <div className={styles["view-profile"]}>
      {!isMobile ? (
        <ProfileHeading
          pageTitle="Profile"
          prevPage={prevPage}
          nextPage={nextPage}
        />
      ) : (
        <div className={styles["view-profile__header"]}>
          <img
            className={styles["view-profile__header__image"]}
            src={profile}
            alt=""
          />
          <h3 className={styles["view-profile__header__head"]}>Profile</h3>
          <img
            src={closeIcon}
            alt="Close"
            className={styles["view-profile__close-btn"]}
            onClick={onClosed}
          />
        </div>
      )}

      <form className={styles["view-profile__form"]}>
        <div className="form__input">
          <label>NAME *</label>
          <input
            type="text"
            name="name"
            placeholder="Samuela"
            value={user.name}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="form__input">
          <label>LAST NAME *</label>
          <input
            type="text"
            name="lastName"
            placeholder="Farmer"
            value={user.lastName}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="form__input">
          <label>FARM *</label>
          <input
            type="text"
            name="farm"
            placeholder="Giri Purno"
            value={user.farm}
            onChange={handleInputChange}
            disabled
          />
        </div>
        <div className="form__input">
          <label>ROLE *</label>
          <input
            type="text"
            name="role"
            value={user.role}
            placeholder="Enter Role"
            onChange={handleInputChange}
            disabled
          />
        </div>
      </form>
    </div>
  );
};

export default ViewProfile;
