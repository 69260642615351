import React from 'react';
import styles from './TaskAssigned.module.scss';
import taskAssignedIcon from '../../assets/icons/tasks/taskAssigned.svg';
import { FormButton } from '../form/Form';

interface Task {
  name: string;
  dueDate: string;
}

interface TaskAssignedProps {
  onGotIt: () => void;
}

const TaskAssigned: React.FC<TaskAssignedProps> = ({onGotIt}: TaskAssignedProps) => {
  const tasks: Task[] = [
    { name: "Fertilization", dueDate: "15/12" },
    { name: "Irrigation", dueDate: "15/12" }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.checkIcon}>
          <img src={taskAssignedIcon} alt="Task Assigned Icon" />
        </div>
        <h2>Task Assigned!</h2>
      </div>
      <p className={styles.subheader}>The following Recommendations have been converted to Tasks:</p>
      <div className={styles.tasksList}>
        {tasks.map((task, index) => (
          <div key={index} className={styles.task}>
            <span className={styles.taskName}>{task.name}</span>
            <span className={styles.dueDate}>DUE {task.dueDate}</span>
            <span className={styles.acceptedLabel}>ACCEPTED</span>
          </div>
        ))}
      </div>
      <FormButton action={onGotIt}>Got It!</FormButton>
    </div>
  );
}

export default TaskAssigned;
