import { Calendar, momentLocalizer, View } from "react-big-calendar";
import moment from "moment";
import MonthlyEvent from "./MonthlyEvent";
import WeeklyEvent from "./WeeklyEvent";

import "react-big-calendar/lib/sass/styles.scss";
import "./CustomCalendar.scss";

import pinIcon from "../../assets/icons/pin.svg";
import Toolbar from "./Toolbar";
import CalendarFilters from "./CalendarFilters";
import { ChangeEvent, useEffect, useState } from "react";

interface TaskList {
  id: number;
  title: string;
  start: Date;
  end: Date;
  priority: string;
  resourcesId: number;
  assignee: string;
  status: string;
}

const resources = [
  { id: 1, title: "John Doe", location: "Giri Purno" },
  { id: 2, title: "John Boo", location: "Giri Purno" },
];

const CustomResourceHeader = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string;
}) => {
  return (
    <>
      <span className="rbc-header-title">{title}</span>
      {location && (
        <span className="rbc-header-subtitle">
          <img src={pinIcon} alt={subtitle} />
          {subtitle}
        </span>
      )}
    </>
  );
};

const CustomWeekday = ({ label, date }: { label: string; date: Date }) => {
  return (
    <>
      <span>{label.split(" ")[1]}</span>
      <span>{label.split(" ")[0]}</span>
    </>
  );
};

export default function CustomCalendar({
  defaultView = "month",
  disableCustomFilters = false,
}: {
  defaultView?: string;
  disableCustomFilters?: boolean;
}) {
  const localizer = momentLocalizer(moment);
  const [tasks, setTasks] = useState<TaskList[]>([
    {
      id: 1,
      title: "Long Event",
      start: new Date(2024, 10, 18, 10, 0),
      end: new Date(2024, 10, 20, 15, 0),
      priority: "high",
      resourcesId: 1,
      assignee: "John Doe",
      status: "In Progress",
    },
    {
      id: 2,
      title: "DTS STARTS",
      start: new Date(new Date().setHours(new Date().getHours() - 3)),
      end: new Date(new Date().setHours(new Date().getHours() + 3)),
      priority: "high",
      resourcesId: 2,
      assignee: "Jack Smith",
      status: "In Progress",
    },
    {
      id: 3,
      title: "Some Other Event",
      start: new Date(new Date().setHours(new Date().getHours() - 3)),
      end: new Date(new Date().setHours(new Date().getHours() + 3)),
      priority: "high",
      resourcesId: 3,
      assignee: "Jill Johnson",
      status: "Halted",
    },
    {
      id: 4,
      title: "Lorem Ipsum",
      start: new Date(new Date().setHours(new Date().getHours() + 124)),
      end: new Date(new Date().setHours(new Date().getHours() + 140)),
      priority: "high",
      resourcesId: 3,
      assignee: "John Doe",
      status: "Halted",
    },
    {
      id: 5,
      title: "Lorem Ipsum",
      start: new Date(new Date().setHours(new Date().getHours() + 75)),
      end: new Date(new Date().setHours(new Date().getHours() + 105)),
      priority: "high",
      resourcesId: 3,
      assignee: "Jenny White",
      status: "In Progress",
    },
    {
      id: 6,
      title: "Lorem Ipsum",
      start: new Date(new Date().setHours(new Date().getHours() + 200)),
      end: new Date(new Date().setHours(new Date().getHours() + 224)),
      priority: "high",
      resourcesId: 3,
      assignee: "Jake Davis",
      status: "Done",
    },
    {
      id: 7,
      title: "Lorem Ipsum",
      start: new Date(new Date().setHours(new Date().getHours() + 350)),
      end: new Date(new Date().setHours(new Date().getHours() + 355)),
      priority: "high",
      resourcesId: 3,
      assignee: "Jake Davis",
      status: "Done",
    },
  ]);
  const [filteredTasks, setFilteredTasks] = useState<TaskList[]>([]);
  const [view, setView] = useState<View>(defaultView as View);
  const [assignee, setAssignee] = useState<string[]>([]);
  const [status, setStatus] = useState<string[]>([]);

  const handleViewChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;

    setView(value.toLocaleLowerCase() as View);
  };

  const handleAssigneeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      setAssignee([...assignee, value]);
    } else {
      setAssignee(assignee.filter((itemValue) => itemValue !== value));
    }
  };

  const handleStatusChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = event.target;

    if (checked) {
      setStatus([...status, value]);
    } else {
      setStatus(status.filter((itemValue) => itemValue !== value));
    }
  };

  useEffect(() => {
    setFilteredTasks(
      tasks.filter(
        (task) =>
          (assignee.length ? assignee.includes(task.assignee) : true) &&
          (status.length ? status.includes(task.status) : true)
      )
    );
  }, [assignee, status]);

  useEffect(() => {
    if (defaultView !== "month") return;

    if (window.innerWidth <= 1024) {
      setView("day");
    } else {
      setView("month");
    }
  }, []);

  return (
    <>
      {!disableCustomFilters && (
        <CalendarFilters
          view={(view.charAt(0).toUpperCase() + view.slice(1)) as string}
          setView={handleViewChange}
          assigneeList={Array.from(new Set(tasks.map((task) => task.assignee)))}
          setAssignee={handleAssigneeChange}
          statusList={Array.from(new Set(tasks.map((task) => task.status)))}
          setStatus={handleStatusChange}
        />
      )}
      <Calendar
        defaultView={view}
        view={view}
        views={["month", "week", "day"]}
        onView={(newView: View) => {
          setView(newView);
        }}
        resources={resources}
        resourceIdAccessor="id"
        resourceAccessor={"id"}
        resourceTitleAccessor="title"
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        events={filteredTasks}
        timeslots={1}
        components={{
          header: CustomWeekday,
          toolbar: (props) => {
            return <Toolbar {...props} />;
          },
          resourceHeader: ({ resource }) => (
            <CustomResourceHeader
              title={resource.title}
              subtitle={resource.location}
            />
          ),
          month: {
            event: ({ event }) => <MonthlyEvent {...event} />,
          },
          week: {
            event: ({ event }) => <WeeklyEvent {...event} />,
          },
          day: {
            event: ({ event }) => <WeeklyEvent {...event} />,
          },
        }}
      />
    </>
  );
}
