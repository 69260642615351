import { useState, useEffect, useContext } from "react";

import { LocaleContext } from "../../useContext/localeContext";

import notificationIcon from "../../assets/icons/notification/notification.svg";
import anotherNotificationIcon from "../../assets/icons/notification/notification2.svg";
import editIcon from "../../assets/icons/notification/edit.svg";
import overdueIcon from "../../assets/icons/notification/overdue.svg";
import sunIcon from "../../assets/icons/notification/sun.svg";

import styles from "../../styles/Notification.module.scss";

import { Trans } from "@lingui/react";

import { Link } from "react-router-dom";

import notificationsData from "./Notification.json";

interface Notifications {
  id: string;
  name: string;
  status: 'inProgress' | 'completed' | 'overdue' | 'upcoming' | 'canceled';
  viewed: boolean;
}


export default function Notification() {
  const [isOpen, setIsOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notifications[]>([]);

  const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem('appLocale') || 'en-US';
    setLocale(currentLocale, 'notification');
  }, [setLocale]);

  useEffect(() => {
    const data = JSON.parse(JSON.stringify(notificationsData));
    const sortedNotifications = [...data].sort((a,b)=>a.viewed-b.viewed);
    setNotifications(JSON.parse(JSON.stringify(sortedNotifications)));
  },[]);

  const handleClose = () => {
    setIsOpen(false);
    const updatedNotifications = notifications.map(notification => ({
      ...notification,
      viewed: true
    }));
    setNotifications(updatedNotifications);
  };

  const unviewedCount: number = notifications.filter(n => !n.viewed).length;
  const displayCount: string | number = unviewedCount > 9 ? '9+' : unviewedCount;

  return (
    <>
      <div className={styles.notification}>
        <button
          className={styles.notification__info}
          type="button"
          onClick={() => setIsOpen(true)}
        >
          <img
            className={styles.notification__icon}
            src={notificationIcon}
            alt="Notifications"
          />
          {unviewedCount > 0 && (
            <span className={styles.notification__label}>
              {displayCount}
            </span>
          )}
        </button>
        <div
          className={`${styles.notification__sidemenu} ${
            isOpen ? styles["notification__sidemenu--is-open"] : ""
          }`}
        >
          <div className={styles.notification__sidemenu__heading}>
            <img
              className={styles.notification__sidemenu__icon}
              src={notificationIcon}
              alt="Notification List Icon"
            />
            <span className={styles.notification__sidemenu__title}>
              <Trans id="notifications.title">Notifications</Trans>
            </span>
            <button
              className={styles["notification__sidemenu__close-btn"]}
              type="button"
              onClick={handleClose}
            >
              X
            </button>
          </div>
          <ul className={styles.notification__list}>
            {notifications.map((notification, index) => (
              <li key={index} className={`${styles.notification__list__item} ${!notification.viewed ? styles['notification__list__item--unviewed'] : ''}`}>
                <img
                  className={styles.notification__list__icon}
                  src={
                    notification.status === "completed"
                      ? overdueIcon
                      : notification.status === "canceled"
                      ? sunIcon
                      : notification.status === "overdue"
                      ? overdueIcon
                      : notification.status === "upcoming"
                      ? editIcon
                      : notification.status === "inProgress"
                      ? anotherNotificationIcon
                      : notificationIcon
                  }
                  alt="Notification Icon"
                />
                <span
                  className={`${styles.notification__list__title} ${
                    !notification.viewed ? styles.bold : ''
                  }`}
                >
                  {notification.status === "completed" &&
                    `Successfully Completed ${notification.name}`}
                  {notification.status === "canceled" && (
                    <>
                      Seems like your {notification.name}.{" "}
                      <Link
                        to="/tasks"
                        className={styles.notification__link}
                      >
                        Find out more
                      </Link>
                    </>
                  )}
                  {notification.status === "overdue" && (
                    <Link to="/tasks" className={styles.notification__link}>
                      Finish your {notification.name}
                    </Link>
                  )}
                  {notification.status === "upcoming" &&
                    `There is an upcoming task for your ${notification.name}`}
                  {notification.status === "inProgress" &&
                    `Don't forget to ${notification.name}`}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}
