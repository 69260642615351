import { FormButton } from "../form/Form";
import styles from "./newUser.module.scss";
import profile from "../../assets/icons/profile/profile.svg";
import closeIcon from "../../assets/icons/close.svg";
import useIsMobile from "../../hooks/useIsMobile";

const NewUser: React.FC<{
  handleAddUser: () => void;
  onClose: () => void;
}> = ({ handleAddUser, onClose }) => {
  const isMobile = useIsMobile();

  return (
    <div className={styles["new-user"]}>
      {isMobile ? (
        <div className={styles["new-user__header"]}>
          <img
            className={styles["new-user__header__image"]}
            src={profile}
            alt=""
          />
          <h3 className={styles["new-user__header__head"]}>New User</h3>
          <img
            src={closeIcon}
            alt="Close"
            className={styles["new-user__close-btn"]}
            onClick={onClose}
          />
        </div>
      ) : (
        ""
      )}
      <form className={styles["new-user__form"]}>
        <div className={styles["form__input"]}>
          <label htmlFor="name">NAME *</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Bridget"
            disabled
          />
        </div>
        <div className={styles["form__input"]}>
          <label htmlFor="last_name">LAST NAME *</label>
          <input
            type="text"
            id="last_name"
            name="last_name"
            placeholder="Jones"
            disabled
          />
        </div>
        <div className={styles["form__input"]}>
          <label htmlFor="farm">FARM *</label>
          <input
            type="text"
            id="farm"
            name="farm"
            placeholder="Giri Purno"
            disabled
          />
        </div>
        <div className={styles["form__input"]}>
          <label htmlFor="role">ROLE *</label>
          <input
            type="text"
            id="role"
            name="role"
            placeholder="Farm Operator"
            disabled
          />
        </div>
      </form>
      <div className={styles["form__buttons"]}>
        <FormButton isTransparent={true} children="Cancel" action={onClose} />
        <FormButton type="submit" children="Add user" action={handleAddUser} />
      </div>
    </div>
  );
};

export default NewUser;
