import { NavLink } from "react-router-dom";
import styles from "../../styles/FarmSetupHeading.module.scss";
import arrLeft from "../../assets/icons/arrLeft.svg";
import arrRight from "../../assets/icons/arrRight.svg";

interface ProfileHeadingProps {
  pageTitle: string;
  prevPage: string | null;
  nextPage: string | null;
}

export default function ProfileHeading({
  pageTitle,
  prevPage,
  nextPage,
}: ProfileHeadingProps) {
  return (
    <div
      className={styles["setup-heading"]}
      style={{
        justifyContent: "space-between",
        marginBottom: "5rem",
      }}
    >
      <NavLink
        className={`${styles.nav__logo} ${!prevPage ? styles.disabled : ""}`}
        to={prevPage || "#"}
        aria-disabled={!prevPage}
      >
        <img src={arrLeft} alt="Go Back" />
      </NavLink>

      <h2 className={styles["setup-heading__heading"]}>{pageTitle}</h2>

      <NavLink
        className={`${styles.nav__logo} ${!nextPage ? styles.disabled : ""}`}
        to={nextPage || "#"}
        aria-disabled={!nextPage}
      >
        <img src={arrRight} alt="Go Forward" />
      </NavLink>
    </div>
  );
}
