import styles from "../../styles/FarmSetupFields.module.scss";
import SetupHeading from "./SetupHeading";
import SingleField from "./SingleField";
import type { SetupProps } from "../../types";
import { useEffect, useContext } from "react";
import { LocaleContext } from "../../useContext/localeContext";
import { Trans } from "@lingui/react";

type TFarmInfoProps = SetupProps;

const FarmFields: React.FC<TFarmInfoProps> = ({ page, changePage }) => {
    const { setLocale } = useContext(LocaleContext);

  useEffect(() => {
    const currentLocale = sessionStorage.getItem('appLocale') || 'en-US';
    setLocale(currentLocale, 'setupFarm');
  }, [setLocale]);

  return (
    <div className={styles["setup-fields"]}>
      <SetupHeading page={page} changePage={changePage} />
      <button
        onClick={() => changePage({ goToIndex: "3" })}
        style={{
          border: "1px solid #538270",
          padding: "1.2rem 3rem",
          backgroundColor: "transparent",
          borderRadius: "1.4rem",
          margin: "3rem 0",
          cursor: "pointer",
        }}
        type="button"
      >
        <Trans id="farmFields.addButton">Add Field</Trans>
      </button>
      <form action="" className={styles["setup-fields__form"]}>
        <SingleField />
      </form>
      <p className={styles["setup-fields__description"]}>
        <Trans id="farmFields.selectFieldsDescription">
          Select the fields you would like to import
        </Trans>
      </p>
      <button
        onClick={() => changePage({ goToIndex: "4" })}
        className="form__button"
        style={{
          width: "30rem",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        {" "}
        <Trans id="farmFields.importButton">Import Your Fields</Trans>
      </button>
    </div>
  );
};

export default FarmFields;
