export default function MonthlyEvent({
  title,
  start,
  end,
  priority,
  assignee,
}: {
  title: string;
  start: Date;
  end: Date;
  priority?: string;
  assignee?: string;
}) {
  return (
    <>
      <div className="rbc-monthly-event rbc-event-wrapper">
        <div className="rbc-monthly-event-content">
          <div className="rbc-monthly-event-content-start">
            {start.getHours()}:{start.getMinutes()}
          </div>
          <div className="rbc-monthly-event-content-title">{title}</div>
          <div className="rbc-monthly-event-content-info">
            {assignee && (
              <span className="rbc-monthly-event-content-info-assignee">
                {assignee.split(" ")[0].charAt(0)}{" "}
                {assignee.split(" ")[1].charAt(0)}
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
