import { useState } from "react";
import { Outlet } from "react-router-dom";
import NestedLayout from "../components/page/NestedLayout";

import fieldInformationLinkIcon from "../assets/icons/field-information.svg";
import fieldTasksLinkIcon from "../assets/icons/field-tasks.svg";
import Modal from "../components/modal/Modal";
import {
  Form,
  FormButton,
  FormGroup,
  FormInput,
  FormTextarea,
} from "../components/form/Form";

import tmpImage from "../assets/area.png";

export default function FieldDetails() {
  const [isNewFieldModalOpen, setNewFieldModalOpen] = useState(false);

  return (
    <>
      <NestedLayout
        pageTitle="Field Details"
        pageIcon={fieldInformationLinkIcon}
        actionTitle="New Field"
        action={() => {
          setNewFieldModalOpen(true);
        }}
        navLinks={[
          {
            icon: fieldInformationLinkIcon,
            label: "Field Information",
            link: "field-information",
          },
          {
            icon: fieldTasksLinkIcon,
            label: "Field Tasks",
            link: "field-tasks",
          },
        ]}
      >
        <Outlet />
      </NestedLayout>
      <Modal
        headingTitle="Add New Field"
        headingSubtitle="Add a new field to your Farm"
        isOpen={isNewFieldModalOpen}
        onClose={() => {
          setNewFieldModalOpen(false);
        }}
      >
        <Form>
          <FormGroup isRow={true}>
            <FormInput
              id="new-field-name"
              name="new-field-name"
              label="FIELD NAME*"
            />
            <FormInput
              id="new-field-area"
              name="new-field-area"
              label="AREA*"
            />
          </FormGroup>
          <FormGroup isRow={true}>
            <FormTextarea
              id="new-field-description"
              name="new-field-description"
              label="DESCRIPTION"
            />
            <FormGroup isRow={true}>
              <FormInput
                id="new-field-valid-from"
                name="new-field-valid-from"
                type="date"
                label="VALID FROM*"
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
              />
              <FormInput
                id="new-field-valid-TO"
                name="new-field-valid-TO"
                type="date"
                label="VALID TO"
                isRequired={true}
                customCSS={{
                  flex: 1,
                }}
              />
            </FormGroup>
          </FormGroup>
          <FormGroup
            isRow={true}
            customCSS={{
              marginBottom: "10rem",
            }}
          >
            <img
              src={tmpImage}
              style={{
                flex: 0.5,
              }}
            />
            <FormGroup>
              <FormTextarea
                id="new-field-coordinates"
                name="new-field-coordinates"
                label="COORDINATES/POLYGON*"
              />
              <FormGroup isRow={true}>
                <FormInput
                  id="new-field-size"
                  name="new-field-size"
                  label="SIZE (HA)*"
                />
                <FormInput
                  id="new-field-area-type"
                  name="new-field-area-type"
                  label="AREA TYPE*"
                />
              </FormGroup>
            </FormGroup>
          </FormGroup>
          <FormGroup isRow={true}>
            <FormButton
              isTransparent={true}
              type="button"
              action={() => {
                setNewFieldModalOpen(false);
              }}
            >
              Cancel
            </FormButton>
            <FormButton>Add Field</FormButton>
          </FormGroup>
        </Form>
      </Modal>
    </>
  );
}
