import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { i18n } from '@lingui/core';

interface LocaleContextType {
  locale: string;
  setLocale: (newLocale: string, section: string) => void;
}

const defaultState = {
  locale: 'en-US',
  setLocale: () => {}
};

export const LocaleContext = createContext<LocaleContextType>(defaultState);

interface Props {
  children: ReactNode;
}

export const LocaleProvider: React.FC<Props> = ({ children }) => {
  const [locale, setLocale] = useState<string>(() => {
    return sessionStorage.getItem('appLocale') || 'en-US';
  });

  const loadLocaleData = async (section: string, newLocale: string) => {
    try {
      const { messages } = await import(`../locales/${section}/${newLocale}/messages`);
      i18n.load(newLocale, messages);
      i18n.activate(newLocale);
    } catch (error) {
      console.error(`Failed to load locale data for section ${section}:`, error);
    }
  };

  useEffect(() => {
    loadLocaleData('profile', locale);
  }, [locale]);

  const handleLocaleChange = (newLocale: string, section: string) => {
    sessionStorage.setItem('appLocale', newLocale);
    setLocale(newLocale);
    loadLocaleData(section, newLocale);
  };

  return (
    <LocaleContext.Provider value={{ locale, setLocale: handleLocaleChange }}>
      {children}
    </LocaleContext.Provider>
  );
};
