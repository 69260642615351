import React, { useState } from "react";
import Heading from "../components/task-recommendations/Heading";
import Pagination from "../components/task-recommendations/Pagination";
import RecommendationsList from "../components/task-recommendations/RecommendationList";
import SectionWrapper from "../components/section/SectionWrapper";
import styles from "../styles/routes/taskRecommendations.module.scss";
import useIsMobile from "../hooks/useIsMobile";

const recommendationsData = [
  { title: "Fertilization recommendation", due: "01/12" },
  { title: "Irrigation recommendation", due: "24/12" },
  { title: "Harvesting recommendation", due: "15/12" },
  { title: "Harvesting recommendation", due: "15/12" },
  { title: "Harvesting recommendation", due: "15/12" },
  { title: "Harvesting recommendation", due: "15/12" },
  { title: "Harvesting recommendation", due: "15/12" },
  { title: "Harvesting recommendation", due: "15/12" },
  { title: "Harvesting recommendation", due: "15/12" },
  { title: "Harvesting recommendation", due: "15/12" },
  { title: "Harvesting recommendation", due: "15/12" },
  { title: "Harvesting recommendation", due: "15/12" },
];

const ITEMS_PER_PAGE = 4;

const TaskRecommendations = () => {
  const isMobile = useIsMobile();
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(recommendationsData.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentPageRecommendations = recommendationsData.slice(startIndex, endIndex);
  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const displayedStart = startIndex + 1;
  const displayedEnd = Math.min(endIndex, recommendationsData.length);

  return (
    <main className={styles.main}>
      <Heading pageTitle="Recommendations" />
      <SectionWrapper
        customStyle={{
          padding: "2rem",
          border: "1px solid var(--clr-lightgreen)",
          borderRadius: isMobile ? "1.6rem" : "1rem",
          marginTop: "2rem",
          display: "flex",
          flexDirection: "column",
          height: "fit-content",
          marginBottom: isMobile ? "5rem" : "0",
          paddingBottom: "2rem",
          scrollbarWidth: "none",
        }}
      >
        {isMobile ? (
          <div className={styles.mobileHeaderWrapper}>
            <h2
              className={styles.subheading}
              style={{ color: "var(--clr-primary)" }}
            >
              Recommendations for your Farm
            </h2>
            <div className={styles.mobileFooter}>
              <span className={styles.paginationText}>
                {displayedStart}-{displayedEnd} of {recommendationsData.length}
              </span>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        ) : (
          <div className={styles.headerWrapper}>
            <div className={styles.header}>
              <h2 className={styles.subheading}>Recommendations for your Farm</h2>
              <span className={styles.paginationText}>
                | {displayedStart}-{displayedEnd} of {recommendationsData.length}
              </span>
            </div>
            <div className={styles.pagination}>
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        )}
        <hr className={styles.divider} />
        <RecommendationsList recommendations={currentPageRecommendations} />
      </SectionWrapper>
    </main>
  );
};

export default TaskRecommendations;
